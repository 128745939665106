import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import Loader from "../../components/Loader/Loader";
import TransferSuccess from "./TransferSuccess";
import "./WithdrawAmount.css";

const WithdrawAmount = () => {
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const { type } = useParams();
  const [isSuccess, setSuccess] = useState(false);

  const { bank_info, paypal_info } = useSelector(
    (store) => store.pages?.bankInfo || {}
  );

  const withdrawhandler = () => {
    if (type == "bank") {
      let payload = {
        ...bank_info,
        amount,
      };
      setLoading(true);
      axios
        .post("/bank", payload)
        .then((res) => {
          if (res?.data?.success) {
            setLoading(false);
            setSuccess(res.data);
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }

          console.log(res);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
          toast.error("Something went wrong try again ..");
        });
    } else if (type == "paypal") {
      let payload = {
        ...paypal_info,
        amount,
      };
      setLoading(true);
      axios
        .post("/paypal", payload)
        .then((res) => {
          if (res?.data?.success) {
            setLoading(false);
            setSuccess(res.data);
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }

          console.log(res);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
          toast.error("Something went wrong try again ..");
        });
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!bank_info && !paypal_info) {
      navigate("/Withdraw");
    }
  }, [bank_info, paypal_info]);

  return (
    <div className="withdraw_amount">
      <Loader loading={loading} />
      <GoBackHeader title="Withdraw" />
      <div className="p-20 Bank-trasfer-page">
        {!isSuccess ? (
          <div className="relative">
            <p className="label">{amount && "Enter Amount"}</p>
            <input
              value={amount}
              type="number"
              placeholder="Enter Amount"
              className={`amount_input ${amount ? " active" : ""} `}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        ) : (
          <div></div>
        )}
        {!isSuccess ? (
          <button
            disabled={!amount || loading}
            onClick={withdrawhandler}
            className="btn  process_btn btn-primary"
          >
            Withdraw
          </button>
        ) : (
          <TransferSuccess data={isSuccess} />
        )}
      </div>
    </div>
  );
};

export default WithdrawAmount;

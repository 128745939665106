import React from "react";

const PlusDouble = (props) => {
  return (
    <svg
      height="64"
      width="64"
      fill="none"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.496 0H21.5144V20.9786H0.535767V38.9602H21.5144V59.9388H39.496V38.9602H60.4746V20.9786H39.496V0ZM24.6384 24.1027H3.65982V42.0843H24.6384V63.0629H42.6201V42.0843H63.5986V24.1027H42.6201V3.12409H24.6384V24.1027ZM25.7645 4.25021V25.2288H4.78595L4.78595 40.9582H25.7645V61.9368H41.4939V40.9582H62.4725V25.2288H41.4939V4.25021H25.7645Z"
        fill="#6789FF"
        fillRule="evenodd"
        opacity="0.3"
      />
    </svg>
  );
};

export default PlusDouble;

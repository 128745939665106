import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AffliatePage from "../Pages/AffliatePage/AffliatePage";
import BankTransfer from "../Pages/BankTransfer/BankTransfer";
import BetDetails from "../Pages/BetDetails/BetDetails";
import Dashboard from "../Pages/Dashboard/Dashboard";
import DownLineList from "../Pages/DownlineList/DownLineList";
import MatchesPage from "../Pages/MatchesPage/MatchesPage";
import MyWalletPage from "../Pages/MyWalletPage/MyWalletPage";
import Notifications from "../Pages/Notifications/Notifications";
import PaypalWithdrawPage from "../Pages/PaypalWithdrawPage/PaypalWithdrawPage";
import ResultHistoryPage from "../Pages/ResultHistoryPage/ResultHistoryPage";
import WithdrawAmount from "../Pages/WithDrawAmount/WithdrawAmount";
import WithdrawPage from "../Pages/WithdrawPage/WithdrawPage";

const Adminroutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/matches" element={<MatchesPage />} />
      <Route path="/Withdraw" element={<WithdrawPage />} />
      <Route path="/Withdraw/addBank" element={<BankTransfer />} />
      <Route path="/Withdraw/Amount/:type" element={<WithdrawAmount />} />
      <Route path="/Withdraw/paypal" element={<PaypalWithdrawPage />} />
      <Route path="/my-wallet" element={<MyWalletPage />} />
      <Route path="/downline" element={<DownLineList />} />
      <Route path="/affliate" element={<AffliatePage />} />
      <Route path="/history" element={<ResultHistoryPage />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/bet/:id" element={<BetDetails />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default Adminroutes;

import ArrowRight from "./ArrowRight"
import Bank from "./Bank"
import Boost from "./Boost"
import Calendar from "./Calendar"
import Check from "./Check"
import ClipBoard from "./ClipBoard"
import Congratulations from "./Congratulations"
import Country1 from "./Country1"
import Country2 from "./Country2"
import Country3 from "./Country3"
import Country4 from "./Country4"
import Danger from "./Danger"
import Email from "./Email"
import Facebook from "./Facebook"
import Google from "./Google"
import HamMenu from "./HamMenu"
import Lock from "./Lock"
import Logout from "./Logout"
import LooseIcon from "./LooseIcon"
import NewDeadline from "./NewDeadline"
import Note from "./Note"
import Notification from "./Notification"
import OrangeBoost from "./OrangeBoost"
import PayPay from "./PayPay"
import Phone from "./Phone"
import RoundAdd from "./RoundAdd"
import Tick from "./Tick"
import Timer from "./Timer"
import TransactionLost from "./TransactionLost"
import TransactionSuccess from "./TransactionSuccess"
import Twitter from "./Twitter"
import User from "./User"
import UserContact from "./UserContact"
import Wallet from "./Wallet"

export const icons = {
    email: <Email />,
    facebook: <Facebook />,
    google: <Google />,
    lock: <Lock />,
    twitter: <Twitter />,
    phone: <Phone />,
    calendar: <Calendar />,
    note: <Note />,
    hamMenu: <HamMenu />,
    notification: <Notification />,
    wallet: <Wallet />,
    userImage: <User />,
    country1: <Country1 />,
    country2: <Country2 />,
    country3: <Country3 />,
    country4: <Country4 />,
    timer: <Timer />,
    newDeadLine: <NewDeadline />,
    looseIcon: <LooseIcon />,
    congratulations: <Congratulations />,
    boost: <Boost />,
    orangeBoost: <OrangeBoost />,
    userContact: <UserContact />,
    logout: <Logout />,
    check: <Check />,
    danger: <Danger />,
    tick: <Tick />,
    clipboard: <ClipBoard />,
    arrowRight: <ArrowRight />,
    transactionSucess: <TransactionSuccess />,
    transsactionLost: <TransactionLost />,
    bank: <Bank />,
    paypal: <PayPay />,
    roundAdd: <RoundAdd />
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assests/svgs/svgExport";
import { imageBaseUrl } from "../../config/constant";
import dateFormat, { masks } from "dateformat";
import { formatDateFunction } from "../Dashboard/reUseFunctions";

const BetHistoryItem = ({ result, event, created_at, updated_at,transaction }) => {
  const navigate = useNavigate();

  let isWin = result == "win";

  return (
    <div className={`bet_card_item ${isWin ? "is_win" : "is_loose"}`}>
      <div className="small_text flex align-center justify-between">
        <div className="flex gap-5px align-center">
          {/* <p>{group}</p>
          <p className="dot"></p>
          <p>{day}</p> */}
        </div>
        <p className="starts_at flex align-center gap-5px">
          {icons.timer}
          {formatDateFunction(updated_at || created_at)}
        </p>
      </div>
      <div className="flex mt-10  align-center justify-between">
        <div className="flex align-center gap-10">
          <div className="team_icon">
            <img src={`${imageBaseUrl}${event?.teamone?.flag}`} alt="" />
          </div>
          <p className="versus">VS</p>
          <div className="team_icon">
            <img src={`${imageBaseUrl}${event?.teamtwo?.flag}`} alt="" />
          </div>
        </div>
        {isWin ? (
          <button className={`bet_button win_btn `}>{`Win RM${transaction?.credit}`}</button>
        ) : (
          <button className={`bet_button loose_btn `}>{`Lose RM${transaction?.debit}`}</button>
        )}
      </div>
    </div>
  );
};

export default BetHistoryItem;

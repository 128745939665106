import React from "react";

const RoundAdd = (props) => {
  return (
    <svg
      height="48"
      width="48"
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" fill="#D9D9D9" r="24" />
      <path
        d="M32 23H26V17C26 16.7348 25.8946 16.4804 25.7071 16.2929C25.5196 16.1054 25.2652 16 25 16C24.7348 16 24.4804 16.1054 24.2929 16.2929C24.1054 16.4804 24 16.7348 24 17V23H18C17.7348 23 17.4804 23.1054 17.2929 23.2929C17.1054 23.4804 17 23.7348 17 24C17 24.2652 17.1054 24.5196 17.2929 24.7071C17.4804 24.8946 17.7348 25 18 25H24V31C24 31.2652 24.1054 31.5196 24.2929 31.7071C24.4804 31.8946 24.7348 32 25 32C25.2652 32 25.5196 31.8946 25.7071 31.7071C25.8946 31.5196 26 31.2652 26 31V25H32C32.2652 25 32.5196 24.8946 32.7071 24.7071C32.8946 24.5196 33 24.2652 33 24C33 23.7348 32.8946 23.4804 32.7071 23.2929C32.5196 23.1054 32.2652 23 32 23Z"
        fill="#777777"
      />
    </svg>
  );
};

export default RoundAdd;

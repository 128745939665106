import React from "react";

const ClipBoard = (props) => {
  return (
    <svg
      height="40"
      width="40"
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" fill="#FBFBFB" r="20" />
      <path
        d="M29.9999 16.2172C29.988 16.1122 29.965 16.0087 29.9313 15.9086V15.8057C29.8764 15.6882 29.8031 15.5802 29.7142 15.4857L22.857 8.62859C22.7626 8.53969 22.6545 8.46639 22.537 8.41144C22.5029 8.4066 22.4683 8.4066 22.4342 8.41144C22.3181 8.34486 22.1899 8.30212 22.057 8.28573H17.4285C16.5192 8.28573 15.6471 8.64695 15.0041 9.28993C14.3611 9.93292 13.9999 10.805 13.9999 11.7143V12.8572H12.857C11.9477 12.8572 11.0757 13.2184 10.4327 13.8614C9.78969 14.5043 9.42847 15.3764 9.42847 16.2857V27.7143C9.42847 28.6236 9.78969 29.4957 10.4327 30.1387C11.0757 30.7816 11.9477 31.1429 12.857 31.1429H21.9999C22.9092 31.1429 23.7813 30.7816 24.4243 30.1387C25.0672 29.4957 25.4285 28.6236 25.4285 27.7143V26.5714H26.5713C27.4806 26.5714 28.3527 26.2102 28.9957 25.5672C29.6387 24.9243 29.9999 24.0522 29.9999 23.1429V16.2172ZM23.1428 12.1829L26.1028 15.1429H24.2856C23.9825 15.1429 23.6918 15.0225 23.4775 14.8081C23.2632 14.5938 23.1428 14.3031 23.1428 14V12.1829ZM23.1428 27.7143C23.1428 28.0174 23.0223 28.3081 22.808 28.5224C22.5937 28.7368 22.303 28.8572 21.9999 28.8572H12.857C12.5539 28.8572 12.2632 28.7368 12.0489 28.5224C11.8346 28.3081 11.7142 28.0174 11.7142 27.7143V16.2857C11.7142 15.9826 11.8346 15.6919 12.0489 15.4776C12.2632 15.2633 12.5539 15.1429 12.857 15.1429H13.9999V23.1429C13.9999 24.0522 14.3611 24.9243 15.0041 25.5672C15.6471 26.2102 16.5192 26.5714 17.4285 26.5714H23.1428V27.7143ZM27.7142 23.1429C27.7142 23.446 27.5938 23.7367 27.3794 23.951C27.1651 24.1653 26.8744 24.2857 26.5713 24.2857H17.4285C17.1254 24.2857 16.8347 24.1653 16.6203 23.951C16.406 23.7367 16.2856 23.446 16.2856 23.1429V11.7143C16.2856 11.4112 16.406 11.1205 16.6203 10.9062C16.8347 10.6919 17.1254 10.5714 17.4285 10.5714H20.857V14C20.857 14.9093 21.2183 15.7814 21.8612 16.4244C22.5042 17.0674 23.3763 17.4286 24.2856 17.4286H27.7142V23.1429Z"
        fill="url(#paint0_linear_85_1380)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_85_1380"
          gradientUnits="userSpaceOnUse"
          x1="9.42847"
          x2="29.9999"
          y1="19.7143"
          y2="19.7143"
        >
          <stop stopColor="#02AAB0" />
          <stop offset="1" stopColor="#00CDAC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ClipBoard;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { icons } from "../../assests/svgs/svgExport";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import Loader from "../../components/Loader/Loader";
import DonwlineBottomCard from "../DownlineList/DonwlineBottomCard";
import "./AffliatePage.css";
const AffliatePage = () => {
  const { user } = useSelector((data) => data?.user);
  const [affData, setAffData] = useState({})
  const [loading,setLoading] = useState(false)

  const clipboardHandler = () => {
    toast.success("Referral Link copied.")
    const referalCode = `${window.location.origin}/register/${user?.user?.code?.code}` || "";
    navigator.clipboard.writeText(referalCode);
  };

  useEffect(() => {
    setLoading(true)
    axios.get('/my-affiliate').then(res => {
      setLoading(false)
      if (res?.data?.success) {
        setAffData(res?.data?.data)
      }
      else {
        toast.error(res?.data?.message||'Failed to fetch Referal Statistics, try again')
      }
    }).catch(e => {
      setLoading(false)
      toast.error(e?.response?.data?.message||'Failed to fetch Referal Statistics, try again')
    })
  },[])
  return (
    <div>
      <div className="bg_primary">
        <Loader loading={loading}  />
        <GoBackHeader title="My Affliate" />
        <div className="affliate-page p-20">
          <p className="affliate-title">
            Get rewarded each time your downline register and play
          </p>
          <div className="affliate-blue-card">
            <div className="top">
              <div className="flex note align-center">
                <p className="number">1</p>
                <p className="text">Share to your friends</p>
              </div>
              <div className="flex note align-center">
                <p className="number">2</p>
                <p className="text">
                 Each downline increase RM1 winning prize
                </p>
              </div>
            </div>
            <div className="bottom">
              <div className="flex align-center justify-between">
                <div className="flex align-center">
                  <p className="text copy_text_1">Your Referral Code :</p>
                  <p className="referal-id">{
                    // user?.user?.code?.code?.length>8 ?(user?.user?.code?.code?.slice(0,8)+"..") :(user?.user?.code?.code || "")}</p>
                    (user?.user?.code?.code || "")}</p>
                </div>
                <div onClick={clipboardHandler} className="icon_cont copy_icon">
                  {icons.clipboard}
                </div>
              </div>
            </div>
          </div>
          <p className="text-small">
            <span className="text-red">*</span>
            Maximum 10x Power (Active Referral)
          </p>
          <p className="section-info mt-40 mb-16">Referal Statistics</p>
          <div className="downline-bottom-cards">
            <DonwlineBottomCard
              type="orange"
              title1="Clicked"
              title2="Links"
              count={affData?.clicked_linkes || 0}
            />
            <DonwlineBottomCard
              type="grad-green"
              title1="Registered"
              title2="User"
              count={affData?.registered_users || 0}
            />
          </div>
          <Link
            to="/downline"
            className="blue-outline-btn mt-40 flex justify-between align-center"
          >
            Your Downline List
            <div className="icon_cont">{icons.arrowRight}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AffliatePage;

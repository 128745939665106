import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assests/svgs/svgExport";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import Loader from "../../components/Loader/Loader";
import MobileWidthLayout from "../../components/MobileWidthLoyout/MobileWidthLayout";
import { fetchNotification } from "../../redux/matchesSlice";
import {
  dateCategorizeHandler,
  datePastCategorizeHandler,
} from "../Dashboard/reUseFunctions";
import { notificationData } from "./data";
import "./Notifcations.css";
const Notifications = () => {
  const [structuredData, setStructureData] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNotification());
  }, []);
  const { notifications, isLoading } = useSelector(
    (store) => store.matchesToBet || {}
  );
  useEffect(() => {
    if (notifications) {
      let structuredData = datePastCategorizeHandler(
        notifications,
        "created_at"
      );
      setStructureData(structuredData);
    }
  }, [notifications]);
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate("/");
  };
  console.log(structuredData, "some ");
  return (
    <>
      <Loader loading={isLoading} />
      <div className="bg_primary">
        <GoBackHeader goBackHandler={goBackHandler} title="Notifications" />
      </div>
      <div className="notifications-cont ">
        {!isLoading && !notifications?.length && (
          <p className="no_data_msg">No notifications ...</p>
        )}
        {structuredData?.map((data) => {
          if (!data?.matches?.length) return;
          return (
            <>
              <p className="section-info mt-20">{data.title}</p>
              {data.matches?.map((bet) => (
                <div className="notification-item flex align-center gap-20">
                  <div className="notification_icon">
                    {bet?.data?.status == "done" && icons.newDeadLine}
                    {bet?.data?.status == "win" && icons.congratulations}
                    {bet?.data?.status == "lost" && icons.looseIcon}
                  </div>
                  <div>
                    <p className="title">{bet?.data?.title}</p>
                    <p className="description">{bet?.data?.description}</p>
                  </div>
                </div>
              ))}
            </>
          );
        })}
      </div>
    </>
  );
};

export default Notifications;

import React from "react";

const Congratulations = (props) => {
  return (
    <svg
      height="40"
      width="40"
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" fill="#CCE6CC" r="20" />
      <path
        d="M33 19.9943L30.1164 16.8065L30.5182 12.5867L26.2518 11.6464L24.0182 8L20 9.67415L15.9818 8L13.7482 11.6464L9.48182 12.5753L9.88364 16.795L7 19.9943L9.88364 23.182L9.48182 27.4133L13.7482 28.3536L15.9818 32L20 30.3144L24.0182 31.9885L26.2518 28.3421L30.5182 27.4018L30.1164 23.182L33 19.9943ZM17.6364 25.7277L12.9091 21.1409L14.5755 19.5241L17.6364 22.4826L25.4245 14.9259L27.0909 16.5542L17.6364 25.7277Z"
        fill="url(#paint0_linear_202_2228)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_202_2228"
          gradientUnits="userSpaceOnUse"
          x1="7"
          x2="33"
          y1="20"
          y2="20"
        >
          <stop stopColor="#02AAB0" />
          <stop offset="1" stopColor="#00CDAC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Congratulations;

import React from "react";

const Country3 = (props) => {
  return (
    <svg
      height="48"
      width="48"
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 1.5C14.175 1.5 5.85002 7.725 2.77502 16.5H45.225C42.15 7.725 33.825 1.5 24 1.5Z"
        fill="#83BF4F"
      />
      <path
        d="M24 46.5C33.825 46.5 42.15 40.275 45.225 31.5H2.77502C5.85002 40.275 14.175 46.5 24 46.5Z"
        fill="#ED4C5C"
      />
      <path
        d="M2.775 16.5C1.95 18.825 1.5 21.375 1.5 24C1.5 26.625 1.95 29.175 2.775 31.5H45.225C46.05 29.175 46.5 26.625 46.5 24C46.5 21.375 46.05 18.825 45.225 16.5H2.775Z"
        fill="#F9F9F9"
      />
      <path
        d="M44.55 33.225L44.325 33.675H44.55V33.225ZM3.67501 33.675L3.45001 33.225V33.675H3.67501ZM4.20001 14.1H3.82501C3.75001 14.175 3.67501 14.25 3.67501 14.325H4.20001V14.1Z"
        fill="white"
      />
      <path
        d="M4.20004 15.75V15.525H3.45004V15.075H4.57504V15.75H6.22504V14.025H6.00004V15.45H5.55004V14.025H5.32504V15.45H4.87504V14.025H4.65004V14.775H3.45004C3.37504 14.925 3.30004 15.15 3.22504 15.3V15.75H4.20004ZM4.20004 33.675H3.67504C3.67504 33.75 3.75004 33.825 3.82504 33.9H4.27504L4.20004 33.675ZM3.45004 33.225H4.57504V33.9H6.22504V32.175H6.00004V33.6H5.55004V32.175H5.32504V33.6H4.87504V32.175H4.65004V32.925H3.37504C3.37504 33.075 3.45004 33.15 3.45004 33.225Z"
        fill="white"
      />
      <path
        d="M4.20001 32.25H3.07501C3.07501 32.325 3.15001 32.4 3.15001 32.475H4.20001V32.25ZM10.2 15.75H11.25V15.525H10.5V15.075H11.625V15.75H13.275V14.025H13.05V15.525H12.6V14.025H12.3V15.525H11.85V14.025H11.625V14.775H10.2V15.75ZM9.75001 15.75V14.775H8.77501V15.075H9.52501V15.525H8.40001V14.775H6.75001V15.825H6.97501V15.075H8.10001V15.75H9.75001Z"
        fill="white"
      />
      <path
        d="M7.34999 15.525H7.72499V15.75H7.34999V15.525ZM6.67499 14.1H11.25V14.325H6.67499V14.1ZM8.77499 33.225H9.52499V33.675H8.39999V32.925H6.74999V33.975H6.97499V33.225H8.09999V33.9H9.74999V32.925H8.77499V33.225Z"
        fill="white"
      />
      <path
        d="M7.34999 33.675H7.72499V33.9H7.34999V33.675ZM6.67499 32.25H11.25V32.475H6.67499V32.25Z"
        fill="white"
      />
      <path
        d="M13.05 33.675H12.6V32.175H12.3V33.675H11.85V32.175H11.625V32.925H10.2V33.9H11.25V33.675H10.5V33.225H11.625V33.9H13.275V32.175H13.05V33.675ZM17.25 15.75H18.225V15.525H17.475V15.075H18.6V15.75H20.25V14.025H20.025V15.525H19.575V14.025H19.35V15.525H18.9V14.025H18.6V14.775H17.25V15.75ZM16.8 15.75V14.775H15.75V15.075H16.5V15.525H15.375V14.775H13.725V15.825H13.95V15.075H15.15V15.75H16.8Z"
        fill="white"
      />
      <path
        d="M13.65 14.1H18.225V14.325H13.65V14.1ZM14.4 15.525H14.775V15.75H14.4V15.525ZM20.025 33.675H19.575V32.175H19.35V33.675H18.9V32.175H18.6V32.925H17.25V33.9H18.225V33.675H17.475V33.225H18.6V33.9H20.25V32.175H20.025V33.675ZM14.4 33.675H14.775V33.9H14.4V33.675Z"
        fill="white"
      />
      <path
        d="M13.65 32.25H18.225V32.475H13.65V32.25ZM15.75 33.225H16.5V33.675H15.375V32.925H13.725V33.975H13.95V33.225H15.15V33.9H16.8V32.925H15.75V33.225ZM23.775 15.75V14.775H22.8V15.075H23.55V15.525H22.35V14.775H20.7V15.825H21V15.075H22.125V15.75H23.775ZM20.7 14.1H25.275V14.325H20.7V14.1Z"
        fill="white"
      />
      <path
        d="M21.375 15.525H21.75V15.75H21.375V15.525ZM24.225 15.75H25.2V15.525H24.45V15.075H25.65V15.75H27.3V14.025H27V15.525H26.55V14.025H26.325V15.525H25.875V14.025H25.65V14.775H24.225V15.75ZM20.7 32.25H25.275V32.475H20.7V32.25ZM22.8 33.225H23.55V33.675H22.35V32.925H20.7V33.975H21V33.225H22.125V33.9H23.775V32.925H22.8V33.225Z"
        fill="white"
      />
      <path
        d="M21.375 33.675H21.75V33.9H21.375V33.675ZM27 33.675H26.55V32.175H26.325V33.675H25.875V32.175H25.65V32.925H24.225V33.9H25.2V33.675H24.45V33.225H25.65V33.9H27.3V32.175H27V33.675ZM28.35 15.525H28.725V15.75H28.35V15.525ZM27.675 14.1H32.25V14.325H27.675V14.1Z"
        fill="white"
      />
      <path
        d="M31.2 15.75H32.25V15.525H31.5V15.075H32.625V15.75H34.275V14.025H34.05V15.525H33.6V14.025H33.3V15.525H32.85V14.025H32.625V14.775H31.2V15.75ZM30.75 15.75V14.775H29.775V15.075H30.525V15.525H29.4V14.775H27.75V15.825H27.975V15.075H29.1V15.75H30.75ZM27.675 32.25H32.25V32.475H27.675V32.25Z"
        fill="white"
      />
      <path
        d="M34.05 33.675H33.6V32.175H33.3V33.675H32.85V32.175H32.625V32.925H31.2V33.9H32.25V33.675H31.5V33.225H32.625V33.9H34.275V32.175H34.05V33.675ZM29.775 33.225H30.525V33.675H29.4V32.925H27.75V33.975H27.975V33.225H29.1V33.9H30.75V32.925H29.775V33.225Z"
        fill="white"
      />
      <path
        d="M28.35 33.675H28.725V33.9H28.35V33.675ZM37.8 15.75V14.775H36.75V15.075H37.5V15.525H36.375V14.775H34.725V15.825H34.95V15.075H36.15V15.75H37.8Z"
        fill="white"
      />
      <path
        d="M35.4 15.525H35.775V15.75H35.4V15.525ZM34.65 14.1H39.225V14.325H34.65V14.1Z"
        fill="white"
      />
      <path
        d="M38.25 15.75H39.225V15.525H38.475V15.075H39.6V15.75H41.25V14.025H41.025V15.525H40.575V14.025H40.35V15.525H39.9V14.025H39.6V14.775H38.25V15.75ZM35.4 33.675H35.775V33.9H35.4V33.675Z"
        fill="white"
      />
      <path
        d="M36.75 33.225H37.5V33.675H36.375V32.925H34.725V33.975H34.95V33.225H36.15V33.9H37.8V32.925H36.75V33.225ZM34.65 32.25H39.225V32.475H34.65V32.25Z"
        fill="white"
      />
      <path
        d="M41.025 33.675H40.575V32.175H40.35V33.675H39.9V32.175H39.6V32.925H38.25V33.9H39.225V33.675H38.475V33.225H39.6V33.9H41.25V32.175H41.025V33.675ZM41.7 14.325H44.325C44.25 14.25 44.25 14.175 44.175 14.1H41.625C41.7 14.1 41.7 14.325 41.7 14.325ZM43.8 15.075H44.55V15.525H43.425V14.85H41.775V15.825H42V15.075H43.125V15.75H44.775V15.375C44.7 15.225 44.625 15 44.55 14.85H43.8V15.075Z"
        fill="white"
      />
      <path
        d="M42.375 15.525H42.75V15.75H42.375V15.525ZM42.375 33.675H42.75V33.9H42.375V33.675ZM41.7 32.25V32.475H44.85C44.85 32.4 44.925 32.325 44.925 32.25H41.7ZM43.8 33.225H44.55C44.625 33.15 44.625 33.075 44.7 32.925H43.8V33.225Z"
        fill="white"
      />
      <path
        d="M43.35 33.675V33H41.7V33.975H42V33.225H43.125V33.9H44.175C44.25 33.825 44.25 33.75 44.325 33.675H43.35Z"
        fill="white"
      />
      <path
        d="M27.375 25.425C28.05 23.475 27.375 21.3 25.875 19.95C27.6 22.5 26.925 25.875 24.525 27.675L24.675 23.475V20.175C24.375 20.025 24.15 19.875 24 19.65C23.85 19.875 23.625 20.1 23.325 20.175V23.475L23.475 27.675C21 25.875 20.4 22.5 22.125 19.95C20.55 21.3 19.95 23.475 20.625 25.425C21.075 26.775 21.975 27.75 23.1 28.35C22.425 28.575 21.675 28.725 20.925 28.725C21.825 29.025 22.725 29.025 23.55 28.875V29.025L24 29.625L24.45 29.025V28.875C25.275 29.025 26.175 29.025 27.075 28.725C26.325 28.725 25.575 28.65 24.9 28.35C26.025 27.75 26.925 26.7 27.375 25.425Z"
        fill="#ED4C5C"
      />
      <path
        d="M29.025 21.825C28.425 20.85 27.45 20.1 26.325 19.8C28.8 21.675 29.325 25.2 27.45 27.675C29.55 26.55 30.225 23.925 29.025 21.825ZM21.6 19.8C20.475 20.1 19.5 20.775 18.9 21.825C17.7 23.85 18.45 26.475 20.475 27.675C18.675 25.2 19.125 21.675 21.6 19.8ZM22.875 19.5C23.25 19.725 23.775 19.65 24 19.275C24.225 19.65 24.75 19.725 25.125 19.5C25.425 19.275 25.575 18.9 25.425 18.525C25.35 18.975 24.975 19.2 24.525 19.125C24.3 19.05 24.15 18.975 24 18.75C23.925 18.975 23.7 19.05 23.475 19.125C23.025 19.2 22.65 18.9 22.575 18.525C22.425 18.9 22.575 19.275 22.875 19.5Z"
        fill="#ED4C5C"
      />
    </svg>
  );
};

export default Country3;

import React from "react";

const OutlinePlus = (props) => {
  return (
    <svg
      height="73"
      width="78"
      fill="none"
      viewBox="0 0 78 73"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.6154 21.6989L59.7217 1.59258L76.0456 17.9165L55.9393 38.0228L55.143 38.8191L55.9393 39.6154L76.0456 59.7217L59.7217 76.0456L39.6154 55.9393L38.8191 55.143L38.0228 55.9393L17.9165 76.0456L1.59258 59.7217L21.6989 39.6154L22.4952 38.8191L21.6989 38.0228L1.59258 17.9165L17.9165 1.59258L38.0228 21.6989L38.8191 22.4952L39.6154 21.6989Z"
        opacity="0.3"
        stroke="#6788FF"
        strokeWidth="2.25225"
      />
    </svg>
  );
};

export default OutlinePlus;

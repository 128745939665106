import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { icons } from "../../assests/svgs/svgExport";
import Loader from "../../components/Loader/Loader";
import { imageBaseUrl } from "../../config/constant";
import {
  betRequestApiAction,
  fetchCurrentMatchDetails,
} from "../../redux/matchesSlice";
import {FaExternalLinkSquareAlt} from 'react-icons/fa'

import { formatDateFunction2 } from "../Dashboard/reUseFunctions";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";

const BetDetailComp = ({ setBetSuccessData }) => {
  const { currentMatch, isLoading } = useSelector(
    (store) => store.matchesToBet
  );
  const [betClick, setBetClick] = useState();
  const [showConfirm, setshowConfirm] = useState(false);
  const betHandler = (id) => {
    setBetClick(id);
  };

  const { id } = useParams();

  const cb = (success, res) => {

    if (success) {
      let betSuccessdata = null;
      if (betClick == currentMatch?.team_one) {
        betSuccessdata = currentMatch?.teamone;
      } else {
        betSuccessdata = currentMatch?.teamtwo;
      }
      setBetSuccessData(betSuccessdata);
    } else {
      toast.error(res?.message || res?.data);
    }
  };

  const dispatch = useDispatch();
  const confirmBetHandler = () => {
    let payload = {
      bet_team_id: betClick,
      event_id: currentMatch?.id,
    };
    dispatch(betRequestApiAction({ cb, payload }));
  };

  useEffect(() => {
    if (!currentMatch) {
      dispatch(fetchCurrentMatchDetails(id));
    }
  }, [currentMatch]);


  const { walletInfo } = useSelector((store) => store.user);

  const fetchPower = () => {
    if (walletInfo.power > 5) {
      return 5
    }
    return walletInfo?.power 
  };


  





  return (
    <div className="BetDetails-cont">
      <ConfirmationModal
        show={showConfirm}
        setShow={setshowConfirm}
        yesHadler={confirmBetHandler}
      />
      <Loader loading={isLoading} />
      <div className="text-center">
        <p className="title">Which Team Will Win The Match</p>
        <div className="flex justify-center align-center text-small">
          <p>Group {currentMatch?.group?.name}</p>
          <div className="dot"></div>
          <p>Match Day {currentMatch?.day || 1}</p>
        </div>
        <p className="text-small">
          {formatDateFunction2(currentMatch?.date)}
          <span className="time ml-5">{currentMatch?.time}</span>
        </p>
      </div>
      <div className="flex bet-cards gap-20">
        <div
          className={`bet-card ${
            currentMatch?.teamone?.id == betClick ? "active" : ""
          }`}
        >
          <div className="logo">
            <img src={`${imageBaseUrl}${currentMatch?.teamone?.flag}`} alt="" />
          </div>
          <p className="country_name">{currentMatch?.teamone?.name}</p>
          <button
            onClick={() => betHandler(currentMatch?.teamone?.id)}
            className={`bet_button ${
              currentMatch?.teamone?.id == betClick ? " active" : ""
            }`}
          >
            {currentMatch?.teamone?.id == betClick ? icons.tick : "Bet"}
          </button>
        </div>
        <div
          className={`bet-card ${
            currentMatch?.teamtwo?.id == betClick ? "active" : ""
          }`}
        >
          <div className="logo">
            <img src={`${imageBaseUrl}${currentMatch?.teamtwo?.flag}`} alt="" />
          </div>
          <p className="country_name">{currentMatch?.teamtwo?.name}</p>
          <button
            onClick={() => betHandler(currentMatch?.teamtwo?.id)}
            className={`bet_button ${
              currentMatch?.teamtwo?.id == betClick ? " active" : ""
            }`}
          >
            {currentMatch?.teamtwo?.id == betClick ? icons.tick : "Bet"}
          </button>
        </div>
      </div>
      <div className="power-up-container">
        <div className="title">
          {icons.orangeBoost}
          <p>WINNING PRIZE</p>
        </div>
        <p className="text-small">
          If Win you get <b>{`RM${fetchPower()?(parseInt(fetchPower())+5):5}`}</b>
        </p>
        <p className="text-small">
          If Lose you get <b>{`-RM${10}`}</b>
        </p>
      </div>

      <Link className="increase_win_btn flex align-center" to='/affliate'>
        {/* <FaExternalLinkSquareAlt /> */}
        Increase Your Winning Prize ?</Link>

      <button
        onClick={() => setshowConfirm(true)}
        disabled={!betClick}
        className="btn  process_btn btn-primary"
      >
        Confirm Bet
      </button>
    </div>
  );
};

export default BetDetailComp;

import React from "react";

const Facebook = (props) => {
  return (
    <svg
      height="40"
      width="41"
      fill="none"
      viewBox="0 0 41 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.5 20.125c0-11.048-8.952-20-20-20s-20 8.952-20 20c0 9.982 7.314 18.257 16.875 19.758V25.906h-5.08v-5.781h5.08v-4.406c0-5.012 2.984-7.781 7.554-7.781 2.189 0 4.478.39 4.478.39v4.92h-2.523c-2.484 0-3.259 1.542-3.259 3.123v3.754h5.547l-.887 5.782h-4.66v13.976C33.186 38.381 40.5 30.107 40.5 20.125Z"
        fill="#4277DD"
      />
    </svg>
  );
};

export default Facebook;

import React from "react";

const Timer = (props) => {
  return (
    <svg
      height="14"
      width="14"
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.06533 7.42267L7.66667 6.61533V3.66667C7.66667 3.48985 7.59643 3.32029 7.4714 3.19526C7.34638 3.07024 7.17681 3 7 3C6.82319 3 6.65362 3.07024 6.52859 3.19526C6.40357 3.32029 6.33333 3.48985 6.33333 3.66667V7C6.33333 7.11702 6.36414 7.23198 6.42265 7.33332C6.48116 7.43467 6.56532 7.51882 6.66667 7.57733L8.39866 8.57733C8.47451 8.6218 8.55841 8.65082 8.64552 8.66273C8.73263 8.67464 8.82123 8.6692 8.90623 8.64673C8.99123 8.62426 9.07094 8.5852 9.14079 8.53179C9.21063 8.47838 9.26921 8.41169 9.31317 8.33555C9.35713 8.25941 9.3856 8.17533 9.39693 8.08814C9.40827 8.00095 9.40224 7.91239 9.3792 7.82754C9.35617 7.74269 9.31658 7.66323 9.26271 7.59375C9.20884 7.52426 9.14176 7.46612 9.06533 7.42267ZM7 0.333332C5.68146 0.333332 4.39252 0.724325 3.2962 1.45687C2.19987 2.18941 1.34539 3.2306 0.840801 4.44878C0.336217 5.66695 0.204195 7.00739 0.461429 8.3006C0.718664 9.59381 1.3536 10.7817 2.28595 11.714C3.2183 12.6464 4.40619 13.2813 5.6994 13.5386C6.9926 13.7958 8.33305 13.6638 9.55122 13.1592C10.7694 12.6546 11.8106 11.8001 12.5431 10.7038C13.2757 9.60747 13.6667 8.31854 13.6667 7C13.6647 5.23248 12.9617 3.53792 11.7119 2.2881C10.4621 1.03828 8.76751 0.335273 7 0.333332ZM7 12.3333C5.94516 12.3333 4.91402 12.0205 4.03696 11.4345C3.15989 10.8485 2.47631 10.0155 2.07264 9.04098C1.66897 8.06644 1.56336 6.99408 1.76914 5.95952C1.97493 4.92495 2.48288 3.97464 3.22876 3.22876C3.97464 2.48288 4.92495 1.97493 5.95952 1.76914C6.99408 1.56336 8.06644 1.66897 9.04098 2.07264C10.0155 2.47631 10.8485 3.1599 11.4345 4.03696C12.0205 4.91402 12.3333 5.94517 12.3333 7C12.3316 8.41395 11.7691 9.76947 10.7693 10.7693C9.76947 11.7691 8.41395 12.3316 7 12.3333Z"
        fill="#ADB0BA"
      />
    </svg>
  );
};

export default Timer;

import React from "react";
import { Link, useNavigate } from "react-router-dom";

import ReactDOM from "react-dom";
import "./SideNav.css";
import { useDispatch, useSelector } from "react-redux";
import { clearUserInfo, signOutUser } from "../../redux/user/userSlice";
import { icons } from "../../assests/svgs/svgExport";
import Loader from "../Loader/Loader";
import { nameGenerate } from "../../config/constant";
import { FaUserAlt } from "react-icons/fa";

const SideNav = ({ closer }) => {
  const navigate = useNavigate();
  const navitemClickHandler = (to) => {
    closer();
    navigate(to);
  };
  const { user, isLoading } = useSelector((data) => data.user || {});
  const dispatch = useDispatch();

  const signInHandler = () => {
    closer();
    if (user) {
      dispatch(signOutUser({ cb: closer }));
      dispatch(clearUserInfo());
    } else {
      navigate("/login");
    }
  };

  console.log(user, "some user");


  return ReactDOM.createPortal(
    <>
      <Loader loading={isLoading} />
      <div className="back_drop" onClick={closer}></div>
      <div className="side_nav_root ">
        <div className="flex flex-column h-100 justify-between">
          <div className="content">
            <div className="side-nav-top close_cont flex justify-between">
              {user?.user && (
                <div className="flex ">
                  <div className="profile_img_cont">
                    {user?.user?.image ?
                      <img src={user?.user?.image} alt="" />:  <FaUserAlt />
                    }
                  </div>
                  <div className="flex flex-column">
                    <p className="profile_name">
                      {user?.user?.name || nameGenerate(user?.user?.email) || ""}
                    </p>
                    <p className="text-light">{user?.user?.email || ""}</p>
                    <Link
                      to="/my-wallet"
                      className="wallet-button flex align-center"
                    >
                      {icons.wallet}
                      <p>My Wallet</p>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="divider"></div>

            <div className="menu_items">
              <div
                onClick={() => {
                  navitemClickHandler("/affliate");
                }}
                className="menu_item flex align-center"
              >
                <div className="icon_cont">{icons.userContact}</div>
                <p className="menu_text">My Affliate</p>
              </div>
            </div>
          </div>

          <button
            onClick={signInHandler}
            className="btn logout flex align-center "
          >
            <div className="logout_icon flex ">{icons.logout}</div>
            {user ? "Log Out" : "Sign In"}
          </button>
        </div>
      </div>
    </>,
    document.getElementById("side_nav")
  );
};

export default SideNav;

import React from "react";

const Country4 = (props) => {
  return (
    <svg
      height="48"
      width="48"
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.5 24C46.5 14.175 40.275 5.85002 31.5 2.77502V45.225C40.275 42.15 46.5 33.825 46.5 24Z"
        fill="#ED4C5C"
      />
      <path
        d="M1.5 24C1.5 33.825 7.8 42.15 16.5 45.225V2.77502C7.8 5.85002 1.5 14.175 1.5 24Z"
        fill="#699635"
      />
      <path
        d="M31.5 2.775C29.175 1.95 26.625 1.5 24 1.5C21.375 1.5 18.825 1.95 16.5 2.775V45.225C18.825 46.05 21.375 46.5 24 46.5C26.625 46.5 29.175 46.05 31.5 45.225V2.775Z"
        fill="#FFE62E"
      />
      <path
        d="M24 27.15L27.975 30L26.475 25.425L30.375 22.575H25.5L24 18L22.5 22.575H17.625L21.525 25.425L20.025 30L24 27.15Z"
        fill="#699635"
      />
    </svg>
  );
};

export default Country4;

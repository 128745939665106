import React from "react";

const Note = (props) => {
  return (
    <svg
      height="24"
      width="19"
      fill="none"
      viewBox="0 0 19 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m12.937 14.076-7.027.027a1.17 1.17 0 1 0 .01 2.342l7.026-.027a1.17 1.17 0 0 0-.01-2.342Zm-.019-4.685-4.684.019a1.171 1.171 0 1 0 .01 2.342l4.683-.019a1.171 1.171 0 1 0-.009-2.342Zm2.315-7.035-1.382.005A3.514 3.514 0 0 0 10.54.032l-2.342.01a3.513 3.513 0 0 0-3.293 2.354l-1.382.005a3.513 3.513 0 0 0-3.5 3.527l.056 14.053a3.513 3.513 0 0 0 3.527 3.5l11.71-.046a3.513 3.513 0 0 0 3.5-3.527L18.76 5.855a3.513 3.513 0 0 0-3.527-3.5ZM7.04 3.559a1.171 1.171 0 0 1 1.167-1.176l2.342-.009a1.171 1.171 0 0 1 1.175 1.167l.005 1.17-4.684.019-.005-1.171Zm9.433 16.358a1.171 1.171 0 0 1-1.167 1.176l-11.71.045a1.17 1.17 0 0 1-1.176-1.166L2.365 5.919a1.171 1.171 0 0 1 1.166-1.175l1.172-.005.004 1.171a1.171 1.171 0 0 0 1.176 1.167l7.026-.028a1.171 1.171 0 0 0 1.167-1.175l-.005-1.171 1.171-.005a1.171 1.171 0 0 1 1.176 1.166l.055 14.053Z"
        fill="#6F7382"
      />
    </svg>
  );
};

export default Note;

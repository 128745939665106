import React from "react";
import { useNavigate } from "react-router-dom";

const TransferSuccess = ({ data }) => {
  const navigate = useNavigate();
  const goBackToHome = () => {
    navigate("/");
  };
  return (
    <div className="transfer-success">
      <p className="title">Transfer Sucess</p>
      <p className="text-light">
        {data?.message || "Transfer request Added successfully..."}
      </p>
      <button
        onClick={goBackToHome}
        className="btn  process_btn btn-primary-outline"
      >
        Back to Homepage
      </button>
    </div>
  );
};

export default TransferSuccess;

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { icons } from "../../../assests/svgs/svgExport";
import { imageBaseUrl } from "../../../config/constant";
import {
  formatDateFunction,
  formatDateFunction2,
} from "../../Dashboard/reUseFunctions";
import "./TeamSelectSuccess.css";
const TeamSelectSuccess = ({ data }) => {
  const navigate = useNavigate();
  const goBackToHome = () => {
    navigate("/");
  };
  const { currentMatch, isLoading } = useSelector(
    (store) => store.matchesToBet
  );
  return (
    <div className="BetDetails-cont">
      <div className="text-center">
        <div className="flex justify-center align-center text-small">
          <p>Group {currentMatch?.group?.name}</p>
          <div className="dot"></div>
          <p>Match Day {currentMatch?.day || 1}</p>
        </div>
        <p className="text-small">
          {formatDateFunction2(currentMatch?.date)}
          <span className="time ml-5">{currentMatch?.time}</span>
        </p>
      </div>

      <div className="selected-team-card card">
        <div className="team-logo">
          <img src={`${imageBaseUrl}${data?.flag}`} alt="" />
        </div>
        <p className="title">{`You Have Bet For ${data.name} to Win In This Match`}</p>
      </div>

      <button
        onClick={goBackToHome}
        className="btn  process_btn btn-primary-outline"
      >
        Back to Homepage
      </button>
    </div>
  );
};

export default TeamSelectSuccess;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assests/svgs/svgExport";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import HeaderChildren from "../../components/HeaderChildren/HeaderChildren";
import Loader from "../../components/Loader/Loader";
import MobileWidthLayout from "../../components/MobileWidthLoyout/MobileWidthLayout";
import PillButton from "../../components/PillButton/PillButton";
import { fetchResultAndHistory } from "../../redux/matchesSlice";
import { dateCategorizeHandler } from "../Dashboard/reUseFunctions";
import { historyMatches, upcomingMatches } from "../Dashboard/sampleData";
import BetHistoryItem from "./BetHistoryItem";
import "./ResultHistoryPage.css";

const ResultHistoryPage = () => {
  const navigate = useNavigate();
  const matchesClickHandler = () => {
    navigate("/matches");
  };
  const resultHistoryClickHandler = () => {
    navigate("/history");
  };

  const [structureData, setStructureData] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchResultAndHistory());
  }, []);

  const { resultAndHistory, isLoading } = useSelector(
    (store) => store.matchesToBet || {}
  );
  // useEffect(() => {
  //   if (resultAndHistory) {
  //     let structuredData = dateCategorizeHandler(
  //       resultAndHistory,
  //       "created_at",
  //       "updated_at"
  //     );
  //     setStructureData(structuredData);
  //   }
  // }, [resultAndHistory]);

  console.log(structureData, "some structuredData");

  const goBackHandler = () => {
    navigate("/");
  };
  const { user } = useSelector((data) => data);
  let isLoggedin = user?.user;

  return (
    <>
      <Loader loading={isLoading} />

      <div className="bg_primary">
        <GoBackHeader>
          <HeaderChildren />
        </GoBackHeader>
      </div>
      <div className="p-20 bg-white">
        {isLoggedin && (
          <div className="categories mb-20 border-none flex gap-15">
            <PillButton
              onClickHandler={matchesClickHandler}
              icon={icons.calendar}
              name="Matches"
            />
            <PillButton
              active
              onClickHandler={resultHistoryClickHandler}
              icon={icons.note}
              name="Result & History"
            />
          </div>
        )}
        {resultAndHistory?.map((match) => {
          return (
            <div className="each_day" key={match.id}>
              <BetHistoryItem key={match.id} {...match} />
            </div>
          );
        })}
        {resultAndHistory && resultAndHistory?.length == 0 && !isLoading && (
          <p className="no_data_msg">No bets done.</p>
        )}
      </div>
    </>
  );
};

export default ResultHistoryPage;

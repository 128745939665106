import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import Loader from "../../components/Loader/Loader";
import { fetchAccountInfo, setPaypalInfo } from "../../redux/pagesSlice";
import "./PaypalWitdhraw.css";

const PaypalWithdrawPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paypalWithdrawHandler = () => {
    dispatch(setPaypalInfo(values));
    navigate("/Withdraw/Amount/paypal");
  };
  const { paypal_info, isLoading } = useSelector(
    (store) => store.pages?.bankInfo || {}
  );
  const { bankInfo } = useSelector((store) => store.pages);
  useEffect(() => {
    if (!paypal_info) dispatch(fetchAccountInfo());
  }, [paypal_info]);

  useEffect(() => {
    if (paypal_info) {
      setValues(paypal_info);
    }
  }, [bankInfo]);

  const [values, setValues] = useState(paypal_info || {});

  return (
    <div className="withdraw_amount bank-info-form ">
      <Loader loading={isLoading} />

      <GoBackHeader title="Withdraw Paypal" />
      <div className="p-20 Bank-trasfer-page">
        <div>
          <div className="input">
            <input
              type="text"
              name="account_on"
              placeholder="Account Number"
              className="basic-input"
              value={values.account_on}
              onChange={(e) =>
                setValues({ ...values, account_on: e.target.value })
              }
            />
          </div>
        </div>

        <button
          disabled={!values.account_on}
          onClick={paypalWithdrawHandler}
          className="btn  process_btn btn-primary"
        >
          Process
        </button>
      </div>
    </div>
  );
};

export default PaypalWithdrawPage;

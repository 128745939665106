import React from 'react'

const Banner = ({url,image}) => {
   const bannerClickHandler = () => {
        window.open(url, '_blank');
    }
  return (
      <div onClick={bannerClickHandler} className="bottom_banner">
          <img src={image} alt="" />
    </div>
  )
}

export default Banner
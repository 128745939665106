import React from "react";

const PayPay = (props) => {
  return (
    <svg
      height="32"
      width="28"
      fill="none"
      viewBox="0 0 28 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_99_1849)">
        <path
          d="M23.0149 2.49083C21.5385 0.80797 18.8696 0.0864868 15.4552 0.0864868H5.5458C5.20798 0.0864993 4.88125 0.207021 4.62434 0.426383C4.36743 0.645745 4.19721 0.949559 4.14426 1.2832L0.0181688 27.4519C-0.0638444 27.9679 0.335732 28.4352 0.858752 28.4352H6.97647L8.5129 18.6899L8.46522 18.9951C8.57467 18.3063 9.16381 17.7982 9.86145 17.7982H12.7686C18.4796 17.7982 22.9515 15.4785 24.2577 8.76813C24.2965 8.56967 24.3301 8.3765 24.3591 8.18779C24.1943 8.10047 24.1943 8.10047 24.3591 8.18779C24.7481 5.70757 24.3565 4.01931 23.0149 2.49083Z"
          fill="#27346A"
        />
        <path
          d="M10.8538 7.29433C11.021 7.21469 11.2039 7.17342 11.3892 7.17353H19.158C20.0779 7.17353 20.936 7.2334 21.7201 7.3596C21.9395 7.39458 22.1578 7.43635 22.3746 7.48484C22.6819 7.55266 22.9857 7.63566 23.2848 7.73353C23.6703 7.86228 24.0293 8.01221 24.3593 8.18779C24.7481 5.70662 24.3565 4.01931 23.0149 2.49083C21.5376 0.80797 18.8696 0.0864868 15.4552 0.0864868H5.54495C4.8472 0.0864868 4.25361 0.59446 4.14426 1.2832L0.0181694 27.4509C-0.0638438 27.9678 0.335732 28.4345 0.857905 28.4345H6.97647L10.1641 8.22042C10.1954 8.02173 10.2746 7.83364 10.3947 7.67231C10.5149 7.51099 10.6724 7.38128 10.8538 7.29433Z"
          fill="#27346A"
        />
        <path
          d="M24.2577 8.76809C22.9515 15.4775 18.4797 17.7981 12.7686 17.7981H9.8606C9.16296 17.7981 8.57371 18.3062 8.46531 18.9951L6.5539 31.1121C6.48248 31.5637 6.83172 31.973 7.28862 31.973H12.4457C12.7411 31.9729 13.0268 31.8674 13.2514 31.6755C13.476 31.4836 13.6248 31.2179 13.6709 30.9261L13.7212 30.6633L14.6931 24.5032L14.7557 24.1627C14.8019 23.871 14.9507 23.6052 15.1752 23.4133C15.3998 23.2214 15.6855 23.116 15.9809 23.1159H16.7527C21.7484 23.1159 25.6601 21.0863 26.8032 15.2165C27.2804 12.7635 27.0335 10.7155 25.7713 9.27702C25.3884 8.84131 24.913 8.48147 24.3591 8.18774C24.3292 8.37741 24.2966 8.56962 24.2577 8.76809Z"
          fill="#2790C3"
        />
        <path
          d="M22.9921 7.6427C22.7884 7.58323 22.5828 7.53057 22.3756 7.48482C22.1586 7.43712 21.9404 7.39564 21.7211 7.36042C20.9361 7.23338 20.0787 7.1734 19.1579 7.1734H11.39C11.2047 7.17298 11.0216 7.21461 10.8546 7.29515C10.673 7.38183 10.5153 7.51147 10.3951 7.67284C10.2749 7.83421 10.1959 8.02244 10.1648 8.22125L8.51375 18.6899L8.46606 18.9951C8.57467 18.3062 9.16381 17.7981 9.86156 17.7981H12.7695C18.4806 17.7981 22.9523 15.4785 24.2586 8.76811C24.2975 8.56964 24.3301 8.37733 24.3601 8.18776C24.0293 8.01314 23.6713 7.86225 23.2857 7.73436C23.1884 7.7021 23.0905 7.67155 22.9922 7.6427"
          fill="#1F264F"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_1849">
          <rect height="32" width="27.1333" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PayPay;

import React from "react";

const PlusFill = (props) => {
  return (
    <svg
      height="85"
      width="85"
      fill="none"
      viewBox="0 0 85 85"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.8985 0.0359497H29.5607V29.5967L0 29.5967L1.10755e-06 54.9345H29.5607V84.4952H54.8985V54.9345H84.4592V29.5967L54.8985 29.5967V0.0359497Z"
        fill="#6788FF"
        opacity="0.3"
      />
    </svg>
  );
};

export default PlusFill;

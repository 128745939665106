import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { icons } from "../../assests/svgs/svgExport";

const HeaderChildren = () => {
  const { walletInfo } = useSelector((store) => store.user||{});

  const fetchRm = () => {
      return walletInfo?.rm < 0 ? 0 :walletInfo?.rm;
  };

  return (
    <div className="flex align-center justify-between">
      <div></div>
      <div className="flex gap-15 betDetail-pills-cont">
        <div className="pill pill-1">
          {icons.boost}
          <p>Power {walletInfo?.power || 0}X</p>
        </div>
        <Link to="/my-wallet" className="pill pill-2">
          {icons.wallet}
          <p>RM{fetchRm()||0}</p>
        </Link>
      </div>
    </div>
  );
};

export default HeaderChildren;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { icons } from "../../assests/svgs/svgExport";
import MobileWidthLayout from "../../components/MobileWidthLoyout/MobileWidthLayout";
import SocialMediaLogins from "../../components/SocialMediaLogins/SocialMediaLogins";
import { signInUser } from "../../redux/user/userSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./LoginPage.css";
import Loader from "../../components/Loader/Loader";
import Logo from '../../assests/images/logo.png'

const LoginPage = () => {
  const [values, setValues] = useState({});

  const inputChangeHandler = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  // const loginClickHandler = () => {
  //   navigate("/");
  // };

  const cb = (success, res) => {
    if (success) {
      toast.success(res?.message || "Logged in Successfully!");
      navigate("/");
    } else {
      toast.error(res?.message || "Invalid credentials , Try again");
    }
  };

  const dispatch = useDispatch();

  const formSubmitHandler = (e) => {
    e.preventDefault();
    console.log(values);
    dispatch(signInUser({ payload: values, cb: cb }));
  };
  const { isLoading } = useSelector((store) => store.user);

  const prams = useParams();
  const logoClickHandler = () => {
    navigate("/")
  }

  return (
    <MobileWidthLayout>
      <Loader loading={isLoading} />
      <div className="login-page">
        <div className="center-in-page">
          <div onClick={logoClickHandler} className="logo-container">
            <img src={Logo} alt="" />
          </div>
          <h2 className="heading">Login</h2>
          <form onSubmit={formSubmitHandler}>
            <div className="input relative">
              <div className="icon_cont">{icons.email}</div>
              <input
                type="text"
                name="email"
                placeholder="Email / Phone Number"
                className="basic-input"
                value={values.email}
                onChange={inputChangeHandler}
              />
            </div>
            <div className="input relative">
              <div className="icon_cont">{icons.lock}</div>
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="basic-input"
                value={values.password}
                onChange={inputChangeHandler}
              />
            </div>
            <div className="flex justify-between">
              <div></div>
              <Link to="/forgot" className="forgot_password">
                Forgot Password ?
              </Link>
            </div>
            <button
              disabled={!values?.password || !values?.email}
              className="login-btn">Log In</button>
          </form>
          <div>
            <SocialMediaLogins />
          </div>
        </div>

        <div className="flex login_bottom align-center justify-center">
          <p className="mr-5">Don't Have Account?</p>
          <Link to="/register">Register</Link>
        </div>
      </div>
    </MobileWidthLayout>
  );
};

export default LoginPage;

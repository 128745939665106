import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { icons } from "../../assests/svgs/svgExport";
import { nameGenerate } from "../../config/constant";
import { formatDateFunction2 } from "../Dashboard/reUseFunctions";

const DownlineUser = ({ IsActive, name, image, created_at ,email }) => {
  return (
    <div className="downline-user-card">
      <div className="flex align-center justify-between">
        <div className="flex align-center">
          <div className="user-photo">
            {image ? <img src={image} alt="" /> : <FaUserAlt />}
          </div>
          <p className="user-name">{name || nameGenerate(email) || "Untitled"}</p>
        </div>
        <div>
          <p className="date">{formatDateFunction2(created_at)}</p>
          {IsActive ? (
            <div className="flex text-green align-center">
              <div className="icon_cont">{icons.check}</div>
              Active User
            </div>
          ) : (
            <div className="flex text-yellow align-center">
              <div className="icon_cont">{icons.danger}</div>
              Inactive User
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DownlineUser;

import React from "react";
import { ClipLoader } from "react-spinners";
import "./Loader.css";

const Loader = ({ loading = false, size = 50 }) => {
  return (
    <div className="loader-root">
      <ClipLoader color={"#1B71C5"} loading={loading} size={size} />
    </div>
  );
};

export default Loader;

import React from "react";

const Google = (props) => {
  return (
    <svg
      height="40"
      width="40"
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0C8.955 0 0 8.955 0 20s8.955 20 20 20 20-8.955 20-20S31.045 0 20 0Zm7.455 28.286c-1.812 1.67-4.285 2.651-7.236 2.651A10.93 10.93 0 0 1 9.286 20c0-1.768.424-3.438 1.165-4.915a10.925 10.925 0 0 1 9.768-6.027c2.946 0 5.42 1.085 7.317 2.848l-3.134 3.139c-1.134-1.085-2.576-1.634-4.179-1.634-2.848 0-5.259 1.924-6.12 4.509a6.577 6.577 0 0 0-.344 2.08c0 .723.125 1.424.344 2.08.861 2.585 3.272 4.51 6.116 4.51 1.473 0 2.723-.39 3.7-1.045a5.027 5.027 0 0 0 2.184-3.304h-5.884V18.01h10.299c.13.719.196 1.464.196 2.236 0 3.335-1.192 6.134-3.259 8.04Z"
        fill="#CC3E52"
      />
    </svg>
  );
};

export default Google;

import './App.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import MobileWidthLayout from './components/MobileWidthLoyout/MobileWidthLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWalletPower, setUserInfo } from './redux/user/userSlice';
import { useEffect } from 'react';

import Adminroutes from './Routes/Adminroutes';
import AuthRoutes from './Routes/AuthRoutes';
import { CookiesProvider } from 'react-cookie';


function App() {
  const { user } = useSelector(data => data)
  let isLoggedin = user?.user;
  const dispatch = useDispatch()
  const userDetails = localStorage.getItem('userDetails')
  if (userDetails) {
    if (!isLoggedin) {
      isLoggedin = true
      dispatch(setUserInfo(JSON.parse(userDetails)))
    }
  }


  useEffect(() => {
    if (isLoggedin)
      dispatch(fetchWalletPower())

  }, [isLoggedin])

  return (
    < >
      <CookiesProvider>
        <ToastContainer />
        <div className="App">
          <MobileWidthLayout >
            <Router>
              {
                isLoggedin ? <Adminroutes /> : <AuthRoutes />
              }

            </Router>
          </MobileWidthLayout>
        </div>
      </CookiesProvider>

    </>
  );
}

export default App;

import React from "react";
import { icons } from "../../assests/svgs/svgExport";
import parse from "html-react-parser";

const TransactionCard = ({ remarks, credit, debit }) => {
  return (
    <div className="transaction-card">
      <div className="flex align-center justify-between">
        <div className="flex align-center">
          <div className="icon_cont">
            {credit ? icons.transactionSucess : icons.transsactionLost}
          </div>
          {credit ? (
            <p className="trans-title">{parse(remarks)}</p>
          ) : (
            <p className="trans-title">{parse(remarks)}</p>
          )}
        </div>
        <p className={`ponts ${debit ? "text-red" : "text-green"}`}>{`${
          credit ? `+${credit}` : `-${debit}`
        } `}</p>
      </div>
    </div>
  );
};

export default TransactionCard;

import React from "react";
import Banner from "./Banner";
import "./Banners.css";
import { BannersData } from "./data";

const Banners = () => {
  return (
      <div className="banners">
       <p className="section-info">Sponsors</p>
      {BannersData?.map((each) => (
        <Banner image={each.image} url={each.url} />
      ))}
    </div>
  );
};

export default Banners;

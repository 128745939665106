import React from "react";

const Boost = (props) => {
  return (
    <svg
      height="16"
      width="13"
      fill="none"
      viewBox="0 0 13 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4175 6.1625C12.3552 6.03882 12.2598 5.9348 12.142 5.86199C12.0242 5.78918 11.8885 5.75042 11.75 5.75H8.00002V1.25C8.00807 1.08551 7.96175 0.92295 7.86822 0.787402C7.7747 0.651854 7.63915 0.550857 7.48252 0.500002C7.33194 0.450459 7.16953 0.449902 7.01861 0.498411C6.8677 0.54692 6.73603 0.642 6.64252 0.770002L0.642518 9.02C0.567342 9.12865 0.522202 9.25523 0.511666 9.38693C0.50113 9.51863 0.525572 9.65078 0.582518 9.77C0.63496 9.90631 0.726059 10.0243 0.844636 10.1096C0.963212 10.1949 1.1041 10.2437 1.25002 10.25H5.00002V14.75C5.00013 14.9082 5.05025 15.0622 5.14319 15.1902C5.23613 15.3182 5.36715 15.4135 5.51752 15.4625C5.59287 15.4859 5.67114 15.4985 5.75002 15.5C5.86836 15.5003 5.98509 15.4726 6.09067 15.4192C6.19626 15.3657 6.2877 15.2881 6.35752 15.1925L12.3575 6.9425C12.4383 6.8306 12.4867 6.69857 12.4973 6.56094C12.5079 6.42332 12.4803 6.28545 12.4175 6.1625ZM6.50002 12.44V9.5C6.50002 9.30109 6.421 9.11032 6.28035 8.96967C6.1397 8.82902 5.94893 8.75 5.75002 8.75H2.75002L6.50002 3.56V6.5C6.50002 6.69891 6.57904 6.88968 6.71969 7.03033C6.86034 7.17098 7.05111 7.25 7.25002 7.25H10.25L6.50002 12.44Z"
        fill="#6F7382"
      />
    </svg>
  );
};

export default Boost;

import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { icons } from "../../assests/svgs/svgExport";
// import FacebookLogin from "react-facebook-login";
// import TwitterLogin from "react-twitter-login";

import "./index.css";
import axios from "axios";
import { toast } from "react-toastify";

const SocialMediaLogins = () => {
  const [loading, setLoading] = useState(false);

  const googleClickHandler = () => {
    setLoading(true);
    axios
      .get("/auth")
      .then((res) => {
        if (res.data?.url) {
          let url = res?.data?.url;
          window.open(url, "_self");
        } else {
          setLoading(false);
          toast.error(
            res?.data?.message || "something went wrong , try again..."
          );
        }
      })
     
  };

  return (
    <>
      <p className="info">Or Continue With</p>
      <div className="social-media-cont flex justify-center align-center gap-15 ">
        <div className="media_icon" onClick={googleClickHandler}>
          <div className="flex google-btn align-center">
            {icons.google}
            <p>Login With Google</p>
          </div>
        </div>
        {/* <FacebookLogin
          appId={3250094451909894}
          callback={facebookResponseHandler}
          icon={<div className="media_icon">{icons.facebook}</div>}
          textButton={null}
          buttonStyle={{
            backgroundColor: "transparent",
            border: "none",
            padding: "0",
          }}
        />
        <TwitterLogin
          authCallback={twitterCallback}
          consumerKey={process.env.REACT_APP_TWITTER_API_KEY}
          consumerSecret={process.env.REACT_APP_TWITTER_APP_SECRET}
        >
          {icons.twitter}
        </TwitterLogin> */}
        {/* <TwitterLogin
          authCallback={twitterCallback}
          consumerKey={process.env.REACT_APP_TWITTER_API_KEY}
          consumerSecret={process.env.REACT_APP_TWITTER_APP_SECRET}
        >
          <div className="media_icon"></div>
        </TwitterLogin> */}
      </div>
    </>
  );
};

export default SocialMediaLogins;

import React, { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./ConfirmationModal.css";

const ConfirmationModal = ({ show, setShow, yesHadler }) => {
  const closer = () => {
    setShow(false);
  };
  return (
    <div className="confirmation-modal">
      <Modal show={show} closeModal={closer}>
        <div className="top-blue"></div>
        <div className="content">
          <p className="title">
            Are you sure to select <br /> this team ?
          </p>
          <div className="buttons-cont">
            <button onClick={yesHadler} className="green">
              Yes
            </button>
            <button onClick={closer} className="secondary">
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;

import React from "react";
import "./PillButton.css";

const PillButton = ({ active, icon, name, onClickHandler = () => {} }) => {
  return (
    <div
      onClick={onClickHandler}
      className={`pill-button-root flex ${active ? "active" : " "}`}
    >
      {icon}
      <p>{name}</p>
    </div>
  );
};

export default PillButton;

import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import Loader from "../../components/Loader/Loader";
import { fetchWalletInfo } from "../../redux/pagesSlice";
import { fetchWalletPower } from "../../redux/user/userSlice";
import { transactionsData } from "./data";
import "./MyWalletPage.css";
import TransactionCard from "./TransactionCard";

const MyWalletPage = () => {
  const [structuredTransactions, setStructuredTransactions] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWalletInfo());
    dispatch(fetchWalletPower())

  }, []);

  const { wallet, isLoading } = useSelector((store) => store.pages);
  useEffect(() => {
    if (wallet?.transaction) {
      let transactions = wallet.transaction;
      transactions = transactions.map((transaction) => {
        let data = { ...transaction };
        data.created_at = transaction.created_at?.slice(0, 10);
        return data;
      });
      const groups = transactions.reduce((groups, item) => {
        const group = groups[item.created_at] || [];
        group.push(item);
        groups[item.created_at] = group;
        return groups;
      }, {});
      setStructuredTransactions(groups);
    }
  }, [wallet]);

  console.log(wallet, "some wallet ");
  return (
    <div>
      <Loader loading={isLoading} />
      <div className="bg_primary">
        <GoBackHeader title="My Wallet" />
        <div className="wallet-page p-20">
          <div className="affliate-blue-card mt-40">
            <div className="top">
              <div className="avail-title">Available Balance</div>
              <div className="flex align-top">
                <p className="rm">RM</p>
                <p className="balance">{wallet?.rm || 0}</p>
              </div>
            </div>
            <div className="bottom">
              <div className="flex align-center justify-between">
                <Link to="/Withdraw" className="withdraw-btn">
                  Withdraw
                </Link>
              </div>
            </div>
          </div>
          <p className="section-info">Transactions</p>
          <div className="">
            {Object.keys(structuredTransactions)?.map((each) => (
              <div key={each}>
                <p className="transaction-date">{each}</p>
                <div className="transactions">
                  {structuredTransactions[each]?.map((transaction) => (
                    <TransactionCard key={transaction?.id} {...transaction} />
                  ))}
                </div>
              </div>
            ))}
            {!Object.keys(structuredTransactions)?.length && !isLoading && (
              <p className="no_data_msg">No transactions found...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyWalletPage;

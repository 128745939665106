import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assests/svgs/svgExport";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import Loader from "../../components/Loader/Loader";
import { fetchAccountInfo } from "../../redux/pagesSlice";
import "./WithdrawPage.css";

const WithdrawPage = () => {
  const dispatch = useDispatch();
  const { bankInfo, isLoading } = useSelector((store) => store.pages);
  useEffect(() => {
    dispatch(fetchAccountInfo());
  }, []);

  const navigate = useNavigate();
  const bankTransferClickHandler = () => {
    // if (bankInfo.bank_info) navigate("/Withdraw/Amount/bank");
    // else
    navigate("/Withdraw/addBank");
  };
  const paypalClickHandler = () => {
    navigate("/Withdraw/paypal");
  };
  return (
    <div>
      <Loader loading={isLoading} />
      <div className="bg_primary">
        <GoBackHeader title="Withdraw" />
        <div className="p-20 withdraw_page">
          <p className="section-heading">Choose Withdrawal Options</p>
          <div
            onClick={paypalClickHandler}
            className="flex align-center gap-20 mt-20 pointer"
          >
            <div className="icon_cont">{icons.paypal}</div>
            <p className="bank_name">PayPal</p>
          </div>
          <div
            onClick={bankTransferClickHandler}
            className="flex align-center gap-20 mt-20 pointer"
          >
            <div className="icon_cont">{icons.bank}</div>
            <p className="bank_name">Transfer to Bank Account</p>
          </div>
          {/* <div className="flex add-another align-center gap-20 mt-20">
            <div className="icon_cont">{icons.roundAdd}</div>
            <p className="bank_name">Add Withdraw Method</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default WithdrawPage;

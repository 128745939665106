import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assests/svgs/svgExport";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import HeaderChildren from "../../components/HeaderChildren/HeaderChildren";
import Loader from "../../components/Loader/Loader";
import PillButton from "../../components/PillButton/PillButton";
import { getCurrentMatches } from "../../redux/matchesSlice";
import BetCardItem from "../Dashboard/BetCardItem/BetCardItem";
import { upcomingMatches } from "../Dashboard/sampleData";
import "./MatchesPage.css";
const MatchesPage = () => {
  const navigate = useNavigate();
  const [structuredMatches, setStructuredMatches] = useState();

  const matchesClickHandler = () => {
    navigate("/matches");
  };
  const resultHistoryClickHandler = () => {
    navigate("/history");
  };

  const dispatch = useDispatch();

  const { isLoading } = useSelector((store) => store.matchesToBet);
  const { matches } = useSelector((store) => store.matchesToBet || {});

  useEffect(() => {
    if (!matches) dispatch(getCurrentMatches());
  }, [matches]);

  useEffect(() => {
    if (matches) {
      let todayMatches = [];
      let TommorrowMatches = [];
      let upcomingMatches = [];
      matches?.map((match) => {
        let matchDate = match.date;
        matchDate = new Date(matchDate).getDate();
        let todayDate = new Date().getDate();
        if (matchDate == todayDate) {
          todayMatches.push(match);
        } else if (matchDate == todayDate + 1) {
          TommorrowMatches.push(match);
        } else {
          upcomingMatches.push(match);
        }
      });
      let structuredData = [
        { id: 1, title: "Today", matches: todayMatches },
        { id: 2, title: "Tommorrow", matches: TommorrowMatches },
        { id: 3, title: "Upcoming", matches: upcomingMatches },
      ];
      setStructuredMatches(structuredData);
    }
  }, [matches]);
  const goBackHandler = () => {
    navigate("/");
  };
  const { user } = useSelector((data) => data);
  let isLoggedin = user?.user;


  return (
    <div className="matches_page">
      <Loader loading={isLoading} />
      <div className="bg_primary ">
        <GoBackHeader goBackHandler={goBackHandler}>
          <HeaderChildren />
        </GoBackHeader>
      </div>
      <div className="p-20 bg-white">
        {isLoggedin && (
          <div className="categories mb-20 border-none flex gap-15">
            <PillButton
              onClickHandler={matchesClickHandler}
              active
              icon={icons.calendar}
              name="Matches"
            />
            <PillButton
              onClickHandler={resultHistoryClickHandler}
              icon={icons.note}
              name="Result & History"
            />
          </div>
        )}
        {structuredMatches?.map((each) =>
          each.matches?.length ? (
            <div className="each_day" key={each.id}>
              <p className="section-info">{each.title}</p>
              {each.matches.map((match) => (
                <BetCardItem key={match.id} {...match} data={match} />
              ))}
            </div>
          ) : null
        )}
        {matches && matches?.length == 0 && !isLoading && (
          <p className="no_data_msg">No matches found to Bet.</p>
        )}
      </div>
    </div>
  );
};

export default MatchesPage;

import React from "react";

const Bank = (props) => {
  return (
    <svg
      height="34"
      width="34"
      fill="none"
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2917 1.41669L2.83333 8.50002V11.3334H29.75V8.50002M22.6667 14.1667V24.0834H26.9167V14.1667M2.83333 31.1667H29.75V26.9167H2.83333M14.1667 14.1667V24.0834H18.4167V14.1667M5.66666 14.1667V24.0834H9.91666V14.1667H5.66666Z"
        fill="url(#paint0_linear_103_1854)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_103_1854"
          gradientUnits="userSpaceOnUse"
          x1="2.83333"
          x2="29.75"
          y1="16.2917"
          y2="16.2917"
        >
          <stop stopColor="#1488CC" />
          <stop offset="1" stopColor="#2B32B2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Bank;

import dateFormat, { masks } from "dateformat";
export const dateCategorizeHandler = (data, property1, property2) => {
    let today = [];
    let tommorrow = [];
    let upcoming = [];
    console.log(data, 'checking error data')
    data?.map((match) => {
        let matchDate = match?.[property1];
        if (property2) {
            matchDate = match?.[property2];
        }
        matchDate = new Date(matchDate).getDate();
        let todayDate = new Date().getDate();
        if (matchDate == todayDate) {
            today.push(match);
        } else if (matchDate == todayDate + 1) {
            tommorrow.push(match);
        } else {
            upcoming.push(match);
        }
    });
    let structuredData = [
        { id: 1, title: "Today", matches: today },
        { id: 2, title: "Tommorrow", matches: tommorrow },
        { id: 3, title: "Upcoming", matches: upcoming },
    ];
    return structuredData
}
export const datePastCategorizeHandler = (data, property1, property2) => {
    let today = [];
    let yesterday = [];
    let older = [];
    data?.map((match) => {
        let matchDate = match?.[property1];
        if (property2) {
            matchDate = match?.[property2];
        }
        matchDate = new Date(matchDate).getDate();
        let todayDate = new Date().getDate();
        if (matchDate == todayDate) {
            today.push(match);
        } else if (matchDate == todayDate - 1) {
            yesterday.push(match);
        } else {
            older.push(match);
        }
    });
    let structuredData = [
        { id: 1, title: "Today", matches: today },
        { id: 2, title: "Yesterday", matches: yesterday },
        { id: 3, title: "Older", matches: older },
    ];
    return structuredData
}

export const formatDateFunction = (date) => {
    let format = dateFormat(date, "ddd, d mmm, h.M tt");
    return format;
};
export const formatDateFunction2 = (date) => {
    let format = dateFormat(date, "dddd, d mmm ");
    return format;
};
export const imageBaseUrl = "https://admin.worldcupmania.club/"


export const reloadToLoginofUnauthenticated = () => {
    let baseURL = window.location.origin;
    let pathname = window.location.pathname;
    let search = window.location.search
    let callback_url = pathname + search
    if(!pathname?.includes('login'))
    window.location.href = `${baseURL}/login${pathname != '/' ? `/?callback=${callback_url}` : ""}`
}
export const nameGenerate = (mail) => {
    if (mail) {
      let name = mail.split("@")?.[0]
      return name
    }
  }
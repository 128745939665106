import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { icons } from "../../../assests/svgs/svgExport";
import { imageBaseUrl } from "../../../config/constant";
import { setCurrentMatch } from "../../../redux/matchesSlice";
import "./BetCardItem.css";

const BetCardItem = ({
  group,
  day,
  time,
  teamone,
  teamtwo,
  id,
  data,
  isBet,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const betClickHandler = () => {
    dispatch(setCurrentMatch(data));
    navigate(`/bet/${id}`);
  };
  return (
    <div className="bet_card_item">
      <div className="small_text flex align-center justify-between">
        <div className="flex gap-5px align-center">
          <p>Group {group?.name}</p>
          <p className="dot"></p>
          <p>Match Day {day || 1}</p>
        </div>
        <p className="starts_at flex align-center gap-5px">
          {icons.timer}
          {time}
        </p>
      </div>
      <div className="flex mt-10  align-center justify-between">
        <div className="flex align-center gap-10">
          <div className="team_icon">
            <img src={`${imageBaseUrl}${teamone.flag}`} alt="" />
          </div>
          <p className="versus">VS</p>
          <div className="team_icon">
            <img src={`${imageBaseUrl}${teamtwo.flag}`} alt="" />
          </div>
        </div>
        {isBet ? (
          <div className="bet_button active">{icons?.tick}</div>
        ) : (
          <div onClick={betClickHandler} className="bet_button">
            Bet
          </div>
        )}
      </div>
    </div>
  );
};

export default BetCardItem;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import Loader from "../../components/Loader/Loader";
import { fetchAccountInfo, setBankInfo } from "../../redux/pagesSlice";
import "./BankTransfer.css";

const BankTransfer = () => {
  const formSubmitHandler = (e) => {
    e.preventDefault();
  };
  const inputChangeHandler = (e) => {
    let name = e.target.name;
    if (name == "account_number") {
    }
    setValues({ ...values, [e.target?.name]: e?.target?.value });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const processBtnHandler = () => {
    dispatch(setBankInfo(values));
    navigate("/Withdraw/Amount/bank");
  };
  const { bank_info, paypal_info, isLoading } = useSelector(
    (store) => store.pages?.bankInfo || {}
  );
  const { bankInfo } = useSelector((store) => store.pages);

  useEffect(() => {
    if (!bank_info) dispatch(fetchAccountInfo());
  }, []);
  useEffect(() => {
    if (bank_info) {
      setValues(bank_info);
    }
  }, [bankInfo]);

  const [values, setValues] = useState(bank_info || {});

  return (
    <div>
      <Loader loading={isLoading} />
      <GoBackHeader title="Bank Account Info" />
      <div className="p-20 Bank-trasfer-page">
        <form className="bank-info-form" onSubmit={formSubmitHandler}>
          <div className="input ">
            <input
              type="text"
              name="account_holder"
              placeholder="Bank Account Holder"
              className="basic-input"
              value={values.account_holder}
              onChange={inputChangeHandler}
            />
            <input
              type="text"
              name="bank_name"
              placeholder="Bank Name"
              className="basic-input"
              value={values.bank_name}
              onChange={inputChangeHandler}
            />
            <input
              type="text"
              name="account_number"
              placeholder="Account Number"
              className="basic-input"
              value={values.account_number}
              onChange={inputChangeHandler}
            />
          </div>
        </form>
        <button
          onClick={processBtnHandler}
          disabled={
            !values?.account_holder ||
            !values?.bank_name ||
            !values?.account_number
          }
          className="btn  process_btn btn-primary"
        >
          Process
        </button>
      </div>
    </div>
  );
};

export default BankTransfer;

import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { icons } from "../../assests/svgs/svgExport";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import Loader from "../../components/Loader/Loader";
import { fetchDownlineList } from "../../redux/pagesSlice";
import DonwlineBottomCard from "./DonwlineBottomCard";
import "./DownLineList.css";
import DownlineUser from "./DownlineUser";
const DownLineList = () => {
  const { downlineList, isLoading } = useSelector((store) => store.pages);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDownlineList());
  }, []);
  return (
    <div className="bg-light-grey page">
      <Loader loading={isLoading} />
      <div className="bg_primary">
        <GoBackHeader title="My Downline" />
      </div>
      <div className="p-20">
        <div className="downline-top-card mb-12">
          <div className="flex mb-15 align-center">
            <div className="icon_cont">{icons.check}</div>
            <div>
              <p className="title-1">Active User</p>
              <p className="text-light">Is user that Always predict a match</p>
            </div>
          </div>
          <div className="flex align-center">
            <div className="icon_cont">{icons.danger}</div>
            <div>
              <p className="title-1">Inactive User</p>
              <p className="text-light">
                Inactive User Is user that didn’t predict a match 3 times in row
              </p>
            </div>
          </div>
        </div>
        <div className="downline-bottom-cards">
          <DonwlineBottomCard
            type="blue"
            title1="Total"
            title2="Downline"
            count={downlineList?.total_downline || 0}
          />
          <DonwlineBottomCard
            type="green"
            title1="Active"
            title2="User"
            count={downlineList?.active_users || 0}
          />
        </div>
        <div className="downline-user-cards">
          {downlineList?.downline_list?.map((each) => (
            <DownlineUser {...each} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DownLineList;

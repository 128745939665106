import { reloadToLoginofUnauthenticated } from "../config/constant";

let restrictCallbackApis = ["/link-clicked"]

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => { 

      let { user } = store.getState(user => user);
      let access_token = user?.user?.access_token
      if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`;
      }
      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(function (response) {
    let apiPath = response?.request?.config?.url;
    if (response.status == 401 && !restrictCallbackApis?.includes(apiPath)) {
      localStorage.removeItem('userDetails')
       reloadToLoginofUnauthenticated()
    }
    return response;
  }, function (error) {
    let apiPath = error?.response?.request?.config.url;

    if (error?.response.status == 401  && !restrictCallbackApis?.includes(apiPath)) {
      localStorage.removeItem('userDetails')
       reloadToLoginofUnauthenticated()
    }
    return Promise.reject(error);
  })
}

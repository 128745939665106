import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { icons } from "../../assests/svgs/svgExport";
import SocialMediaLogins from "../../components/SocialMediaLogins/SocialMediaLogins";
import "./RegisterPage.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { signupUser } from "../../redux/user/userSlice";
import Loader from "../../components/Loader/Loader";
import Logo from "../../assests/images/logo.png";
import { useCookies } from "react-cookie";
import axios from "axios";

const RegisterPage = () => {
  const { referBy } = useParams();

  const [cookies, setCookie] = useCookies(["refer"]);

  const setReferDoneCookie = () => {
    setCookie("refer", "yes", { path: "/", maxAge: 24 * 60 * 60 * 365 });
  };
  const isReferDone = () => {
    return cookies?.refer == "yes";
  };

  const setReferClickCookie = () => {
    setCookie(referBy, "yes", { path: "/", maxAge: 24 * 60 * 60 * 365 });
  };
  const isReferClickDone = () => {
    return cookies?.[referBy] == "yes";
  };

  const [values, setValues] = useState({
    email: "",
    password: "",
    phone: "",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const linkClickApiHandler = () => {
    if (!isReferClickDone()) {
      let payload = {
        code: referBy,
      };
      axios
        .post("/link-clicked", payload)
        .then((res) => {
          if (res?.data?.success) {
            setReferClickCookie();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (referBy) {
      linkClickApiHandler()
      setValues({ ...values, refer_by: referBy });
      localStorage.setItem("referBy", referBy);
    }
  }, []);

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const cb = (success, res) => {
    if (success) {
      setReferDoneCookie();
      toast.success(res?.data?.message || "User Created Successfully");
      navigate("/");
    } else {
      if (res?.errors) {
        setErrors(res?.errors);
        return;
      }
      toast.error(res?.data?.message || "Something went wrong, Try again");
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    let payload = { ...values };
    if (isReferDone) {
      delete payload.refer_by;
    }
    dispatch(signupUser({ payload: payload, cb: cb }));
  };
  const { isLoading } = useSelector((store) => store.user);
  const logoClickHandler = () => {
    navigate("/");
  };


  console.log(cookies,'some cookies')

  return (
    <>
      <Loader loading={isLoading} />

      <div className="login-page">
        <div className="center-in-page">
          <div onClick={logoClickHandler} className="logo-container">
            <img src={Logo} alt="" />
          </div>
          <h2 className="heading">Register</h2>
          <form onSubmit={formSubmitHandler}>
            <div className="flex flex-column">
              <div
                className={`input relative ${errors?.phone ? " error" : ""}`}
              >
                <div className="icon_cont">{icons.phone}</div>
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="phone"
                  className="basic-input"
                  value={values.phone}
                  required
                  onChange={inputChangeHandler}
                />
              </div>
              {errors?.phone?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex flex-column">
              <div
                className={`input relative ${errors?.email ? " error" : ""}`}
              >
                <div className="icon_cont">{icons.email}</div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="basic-input"
                  value={values.email}
                  onChange={inputChangeHandler}
                  required
                />
              </div>
              <p className="hint-message">
                Real email for cashout confirmation
              </p>
              {errors?.email?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <div
                className={`input relative ${errors?.password ? " error" : ""}`}
              >
                <div className="icon_cont">{icons.lock}</div>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="basic-input"
                  value={values.password}
                  onChange={inputChangeHandler}
                />
              </div>
              {errors?.password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <div className={`input relative`}>
                <div style={{ opacity: 0.4 }} className="icon_cont">
                  {icons.userContact}
                </div>
                <input
                  type="text"
                  name="refer_by"
                  placeholder="Refer By"
                  className="basic-input"
                  value={values.refer_by}
                  onChange={inputChangeHandler}
                />
              </div>
              {errors?.refer_by?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex justify-between">
              <div></div>
              <Link to="/forgot" className="forgot_password">
                Forgot Password ?
              </Link>
            </div>
            <button disabled={isLoading} className="login-btn">
              Register
            </button>
          </form>
          <div>
            <SocialMediaLogins />
          </div>
        </div>

        <div className="flex login_bottom align-center justify-center">
          <p className="mr-5">Already Have Account?</p>
          <Link to="/login">Login</Link>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;

import React, { useState } from "react";
import "./NavBar.css";
import { IoNotificationsOutline } from "react-icons/io5";
import SideNav from "../SideNav/SideNav";
import { Link, useNavigate } from "react-router-dom";
import { icons } from "../../assests/svgs/svgExport";
import { useSelector } from "react-redux";
import Logo from "../../assests/images/logo.png";

const NavBar = () => {
  const [showSideNav, setShowSideNav] = useState(false);
  const openSideNav = () => {
    setShowSideNav(true);
  };
  const closeSideNav = () => {
    setShowSideNav(false);
  };
  const navigate = useNavigate();
  const notifyClickHandler = () => {
    navigate("/notifications");
  };

  const { walletInfo } = useSelector((store) => store.user||{});

  const fetchRm = () => {
      return walletInfo?.rm < 0 ? 0 :walletInfo?.rm;
  };

  const gotoHomeHandler = () => {
    navigate("/");
  }
  return (
    <>
      {showSideNav && <SideNav closer={closeSideNav} />}
      <div className="bg_primary sticky_top_nav">
        <nav className="root_nav_bar">
          <div className="flex align-center">
            <div onClick={openSideNav} className="icon_cont pointer">
              {icons.hamMenu}
            </div>
            <img onClick={gotoHomeHandler} className="nav_logo" src={Logo} alt="" />
          </div>

          <div className="flex align-center gap-15">
            <Link to="/my-wallet" className="wallet_info flex align-center">
              {icons.wallet}
              {`RM${fetchRm()||0}`}
            </Link>
            <div className="relative icon_cont pointer ">
              <div
                className="badge_dot badge_green"
                onClick={notifyClickHandler}
              ></div>
              {icons.notification}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavBar;

import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import OutlinePlus from "../../assests/svgs/OutlinePlus";
import PlusDouble from "../../assests/svgs/PlusDouble";
import PlusFill from "../../assests/svgs/PlusFill";
import { icons } from "../../assests/svgs/svgExport";
import Loader from "../../components/Loader/Loader";
import TopNavBarWrapper from "../../components/NavBarWrapper/NavBarWrapper";
import PillButton from "../../components/PillButton/PillButton";
import { getCurrentMatches } from "../../redux/matchesSlice";
import GoogleCallback from "../LoginPage/GoogleCallback";
import BetCardItem from "./BetCardItem/BetCardItem";
import "./Dashboard.css";
import { dateCategorizeHandler } from "./reUseFunctions";
import { upcomingMatches } from "./sampleData";
import puzzleImage from "../../assests/images/Puzzle.png";
import CircleDots from "../../assests/svgs/CircleDots";
import wallet from "../../assests/images/wallet.png";
import trophy from "../../assests/images/Trophy.png";
import medal from "../../assests/images/Medal.png";
import Banners from "../../components/Banners/Banners";
const Dashboard = () => {
  const [structuredMatches, setStructuredMatches] = useState();
  const navigate = useNavigate();
  const matchesClickHandler = () => {
    navigate("/matches");
  };
  const historyClickHandler = () => {
    navigate("/history");
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentMatches());
  }, []);

  const { isLoading } = useSelector((store) => store.matchesToBet);
  const { matches } = useSelector((store) => store.matchesToBet || {});

  useEffect(() => {
    if (matches) {
      let structuredData = dateCategorizeHandler(matches, "date");
      setStructuredMatches(structuredData);
    }
  }, [matches]);

  console.log(matches, "some matches");
  console.log(structuredMatches, "structured");

  const { user } = useSelector((data) => data);
  let isLoggedin = user?.user;

  // const urlSearchParams = new URLSearchParams(window.location.search);
  // if (urlSearchParams?.get("code")) {
  //   window.open(
  //     `http://${window.location.host}/#/auth/google${window.location.search}`,
  //     "_self"
  //   );
  //   return;
  // }

  return (
    <>
      <Loader loading={isLoading} />
      <TopNavBarWrapper>
        <div className="dashbaord">
          <div className="cards_scroll">
            <div className="flex gap-10">
              <div className="banner bg_blue relative">
                <img src={puzzleImage} alt="" />
                <p className="text">
                  <span>FREE</span>
                  <span>TO</span>
                  <span>PLAY</span>
                </p>
                <div className="plusFill">
                  <PlusFill />
                </div>
                <div className="plusDouble">
                  <PlusDouble />
                </div>
                <div className="plusOutline">
                  <OutlinePlus />
                </div>
              </div>
              <div className="banner bg_orange relative">
                <img src={trophy} alt="" />
                <div className="dots-1">
                  <CircleDots />
                </div>
                <div className="dots-2">
                  <CircleDots />
                </div>
                <p className="text">
                  Winning Prize You Get RM 5, and Lose You Will Lost -RM 10 Each
                  Matches
                </p>
              </div>
              <div className="banner bg_purple relative">
                <img src={wallet} alt="" />
                <p className="text">
                  You Allow to Withdraw at The End of The Matches
                </p>
              </div>
              <div className="banner bg_red relative">
                <img src={medal} alt="" />
                <p className="text">
                  Increase your winning prize from RM 5 - RM 15
                </p>
              </div>
            </div>
          </div>
          <div className="p-20">
            {isLoggedin && (
              <div className="categories flex gap-15">
                <PillButton
                  onClickHandler={matchesClickHandler}
                  icon={icons.calendar}
                  name="Matches"
                />
                <PillButton
                  icon={icons.note}
                  name="Result & History"
                  onClickHandler={historyClickHandler}
                />
              </div>
            )}
            {structuredMatches?.map((each) =>
              each.matches?.length ? (
                <div className="each_day" key={each.id}>
                  <p className="section-info">{each.title}</p>
                  {each.matches.map((match) => (
                    <BetCardItem key={match.id} {...match} data={match} />
                  ))}
                </div>
              ) : null
            )}
            {matches && matches?.length == 0 && !isLoading && (
              <p className="no_data_msg">No matches found to Bet.</p>
            )}
          </div>
          <Banners />
        </div>
        
      </TopNavBarWrapper>
    </>
  );
};

export default Dashboard;

import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user/userSlice'
import matchesReducer from './matchesSlice'
import pagesReducer from './pagesSlice'

export const store = configureStore({
    reducer: {
        // dashboard: dashboardReducer
        user: userReducer,
        matchesToBet: matchesReducer,
        pages: pagesReducer
    },
})
import React from "react";

const DonwlineBottomCard = ({ type, title1, title2, count }) => {
  return (
    <div className={`downline-bottom-card ${type}`}>
      <p className="title">
        {title1} <br /> {title2}
      </p>
      <p className="count">{count || 0}</p>
    </div>
  );
};

export default DonwlineBottomCard;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { icons } from "../../assests/svgs/svgExport";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import HeaderChildren from "../../components/HeaderChildren/HeaderChildren";
import MobileWidthLayout from "../../components/MobileWidthLoyout/MobileWidthLayout";
import BetDetailComp from "./BetDetailComp";
import "./BetDetails.css";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import TeamSelectSuccess from "./TeamSelectSuccess/TeamSelectSuccess";

const BetDetails = () => {
  const [betSuccessData, setBetSuccessData] = useState(null);

  console.log(betSuccessData, "bet sucess data");
  return (
    <div>
      <ConfirmationModal />
      <div className="bg_primary">
        <GoBackHeader>
          <HeaderChildren />
        </GoBackHeader>
      </div>
      {betSuccessData ? (
        <TeamSelectSuccess data={betSuccessData} />
      ) : (
        <BetDetailComp setBetSuccessData={setBetSuccessData} />
      )}
    </div>
  );
};

export default BetDetails;

import React from "react";

const LooseIcon = (props) => {
  return (
    <svg
      height="40"
      width="40"
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" fill="#FFCCCC" r="20" />
      <path
        d="M28.4375 25.625C28.4375 27.1719 27.1719 28.4375 25.625 28.4375H14.375C12.8281 28.4375 11.5625 27.1719 11.5625 25.625V14.375C11.5625 12.8281 12.8281 11.5625 14.375 11.5625H25.625C27.1719 11.5625 28.4375 12.8281 28.4375 14.375V25.625Z"
        fill="url(#paint0_linear_202_2231)"
      />
      <path
        d="M25.0625 16.9625L23.0375 14.9375L20 17.975L16.9625 14.9375L14.9375 16.9625L17.975 20L14.9375 23.0375L16.9625 25.0625L20 22.025L23.0375 25.0625L25.0625 23.0375L22.025 20L25.0625 16.9625Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_202_2231"
          gradientUnits="userSpaceOnUse"
          x1="11.5625"
          x2="28.4375"
          y1="20"
          y2="20"
        >
          <stop stopColor="#C84E89" />
          <stop offset="1" stopColor="#F15F79" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LooseIcon;

import React from "react";

const Wallet = (props) => {
  return (
    <svg
      height="22"
      width="25"
      fill="none"
      viewBox="0 0 25 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.542 4.833h-1.209V3.625A3.625 3.625 0 0 0 15.708 0H3.625A3.625 3.625 0 0 0 0 3.625v14.5a3.625 3.625 0 0 0 3.625 3.625h16.917a3.625 3.625 0 0 0 3.625-3.625V8.458a3.625 3.625 0 0 0-3.625-3.625ZM3.625 2.417h12.083a1.209 1.209 0 0 1 1.209 1.208v1.208H3.625a1.208 1.208 0 1 1 0-2.416ZM21.75 14.5h-1.208a1.209 1.209 0 0 1 0-2.417h1.208V14.5Zm0-4.833h-1.208a3.625 3.625 0 0 0 0 7.25h1.208v1.208a1.208 1.208 0 0 1-1.208 1.208H3.625a1.208 1.208 0 0 1-1.208-1.208V7.045c.388.136.796.206 1.208.205h16.917a1.208 1.208 0 0 1 1.208 1.208v1.209Z"
        fill="#6F7382"
      />
    </svg>
  );
};

export default Wallet;


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    matches: null,
    isLoading: false,
    resultAndHistory: [],
    notifications: null,
    currentMatch: null

}

export const getCurrentMatches = createAsyncThunk('/matches', () => {
    return axios.get('/matches').then(res => res.data)
});
export const fetchCurrentMatchDetails = createAsyncThunk('/matches/each', (id) => {
    return axios.get(`/matches?id=${id}`).then(res => res.data)
});
export const fetchResultAndHistory = createAsyncThunk('/result-history', () => {
    return axios.get('/result-history').then(res => res.data)
});
export const fetchNotification = createAsyncThunk('/user/notifications', () => {
    return axios.get('/user/notifications').then(res => res.data)
});
export const betRequestApiAction = createAsyncThunk('/bet', (data) => {
    return axios.post('/bet', data.payload).then(res => {
        if (res.data.success)
            data.cb(true, res.data)
        else
            data.cb(false, res?.data)

        return res.data
    }
    ).catch(e => {
        data.cb(false, e.response?.data)
        return e.response
    })
});



export const matchesSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setCurrentMatch: (state, action) => {
            state.currentMatch = action.payload;
        },

    },
    extraReducers: {
        [getCurrentMatches.pending]: (state) => {
            state.isLoading = true;
        },
        [getCurrentMatches.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.matches = action.payload.data;
        },
        [getCurrentMatches.rejected]: (state) => {
            state.isLoading = false;
        },
        // ########### Current match #######################
        [fetchCurrentMatchDetails.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchCurrentMatchDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.currentMatch = action.payload.data?.length ? action.payload.data[0] : null;
        },
        [fetchCurrentMatchDetails.rejected]: (state) => {
            state.isLoading = false;
        },
        // ##### HISTORY API ################
        [fetchResultAndHistory.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchResultAndHistory.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.resultAndHistory = action.payload.data;
        },
        [fetchResultAndHistory.rejected]: (state) => {
            state.isLoading = false;
        },
        // ##### fetchNotification API ################
        [fetchNotification.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchNotification.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.notifications = action.payload.data;
        },
        [fetchNotification.rejected]: (state) => {
            state.isLoading = false;
        },
        /// ########## Bet request ################
        [betRequestApiAction.pending]: (state) => {
            state.isLoading = true;
        },
        [betRequestApiAction.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [betRequestApiAction.rejected]: (state) => {
            state.isLoading = false;
        },
    },
})

export const { setCurrentMatch } = matchesSlice.actions

export default matchesSlice.reducer;

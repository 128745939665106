import React from "react";

const Notification = (props) => {
  return (
    <svg
      height="21"
      width="17"
      fill="none"
      viewBox="0 0 17 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.16 11.309V8.091a6.068 6.068 0 0 0-5.057-5.978V1.011a1.011 1.011 0 0 0-2.023 0v1.103a6.069 6.069 0 0 0-5.057 5.978v3.216A3.034 3.034 0 0 0 0 14.162v2.023a1.011 1.011 0 0 0 1.011 1.011h3.177a4.046 4.046 0 0 0 7.808 0h3.176a1.011 1.011 0 0 0 1.012-1.011V14.16a3.035 3.035 0 0 0-2.023-2.852ZM4.047 8.091a4.046 4.046 0 1 1 8.092 0v3.034H4.046V8.092Zm4.046 10.115a2.022 2.022 0 0 1-1.74-1.012h3.48a2.022 2.022 0 0 1-1.74 1.012Zm6.069-3.035H2.023V14.16a1.012 1.012 0 0 1 1.011-1.012H13.15a1.012 1.012 0 0 1 1.012 1.012v1.011Z"
        fill="#6F7382"
      />
    </svg>
  );
};

export default Notification;

import React from "react";

const Calendar = (props) => {
  return (
    <svg
      height="28"
      width="28"
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.375 2.75h-2.75V1.375a1.375 1.375 0 0 0-2.75 0V2.75h-8.25V1.375a1.375 1.375 0 0 0-2.75 0V2.75h-2.75A4.125 4.125 0 0 0 0 6.875v16.5A4.125 4.125 0 0 0 4.125 27.5h19.25a4.125 4.125 0 0 0 4.125-4.125v-16.5a4.125 4.125 0 0 0-4.125-4.125Zm1.375 20.625a1.375 1.375 0 0 1-1.375 1.375H4.125a1.375 1.375 0 0 1-1.375-1.375V13.75h22v9.625Zm0-12.375h-22V6.875A1.375 1.375 0 0 1 4.125 5.5h2.75v1.375a1.375 1.375 0 0 0 2.75 0V5.5h8.25v1.375a1.375 1.375 0 0 0 2.75 0V5.5h2.75a1.375 1.375 0 0 1 1.375 1.375V11Z"
        fill="#6F7382"
      />
    </svg>
  );
};

export default Calendar;

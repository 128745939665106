
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios'
const initialState = {
    isLoading: false,
    user: null,
    walletInfo: null

}

export const signInUser = createAsyncThunk('/login', (data) => {
    return axios.post('/login', data.payload).then(res => {
        if (res.data.success)
            data.cb(true, res.data)
        else {
            data.cb(false, res?.data)
            throw new Error()
        }

        return res.data
    }
    ).catch(e => {
        data.cb(false, e.response?.data)
        throw new Error(e.response?.data)
        
    })
});
export const signOutUser = createAsyncThunk('/logout', (data) => {
    localStorage.removeItem('userDetails');
    return axios.post('/logout', data.payload).then(res => {
        if (res.data.success)
            data.cb(true, res.data)
        else
            data.cb(false, res?.data)
        return res.data
    }
    )
});
export const fetchWalletPower = createAsyncThunk('/user/wallet', () => {
    return axios.get('/user/wallet').then(res => res.data)
});

export const signupUser = createAsyncThunk('/register', (data) => {
    return axios.post('/register', data.payload).then(res => {
        let user
        if (res.data.success) {
            data.cb(true, res.data)
            // user = {
            //     phone: data?.payload?.phone,
            //     email: data?.payload?.email,
            //     user: res?.data?.data
            // }
            localStorage.setItem('userDetails', JSON.stringify(res?.data?.data))
        }
        else
            data.cb(false, res?.data)
        return user || res.data
    }
    ).catch(e => {
        data.cb(false, e.response)
        throw  new Error(e.response?.data);
    })
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.user = action.payload;
        },
        clearUserInfo: (state, action) => {
            localStorage.removeItem('userDetails')
            state.user = null
            state.isLoading = false
        }
    },
    extraReducers: {
        [signOutUser.pending]: (state) => {
            state.isLoading = true;
        },
        [signOutUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            localStorage.removeItem('userDetails');
            state.user = null;
        },
        [signOutUser.rejected]: (state) => {
            state.isLoading = false;
        },
        [signInUser.pending]: (state) => {
            state.isLoading = true;
        },
        [signInUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            let user = action.payload?.data;
            localStorage.setItem('userDetails', JSON.stringify(user))
            state.user = { ...user }
        },
        [signInUser.rejected]: (state) => {

            state.isLoading = false;
        },
        [fetchWalletPower.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchWalletPower.fulfilled]: (state, action) => {
            state.isLoading = false;
            let walletInfo = action.payload?.data;
            state.walletInfo = walletInfo
        },
        [fetchWalletPower.rejected]: (state) => {

            state.isLoading = false;
        },
        [signupUser.pending]: (state) => {
            state.isLoading = true;
        },
        [signupUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.user = action.payload.data;
        },
        [signupUser.rejected]: (state) => {
            state.isLoading = false;
        },
    },
})

export const { setUserInfo, clearUserInfo } = userSlice.actions

export default userSlice.reducer;

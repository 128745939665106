import React from "react";

const Country2 = (props) => {
  return (
    <svg
      height="48"
      width="48"
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5 1.95001H19.5C10.725 3.75001 3.75001 10.65 1.95001 19.5V28.5C3.75001 37.35 10.725 44.25 19.5 46.05H28.5C37.35 44.25 44.25 37.275 46.05 28.5V19.5C44.25 10.65 37.275 3.75001 28.5 1.95001Z"
        fill="white"
      />
      <path
        d="M45.8843 29.25C46.2945 27.5302 46.5012 25.7681 46.5 24C46.5 22.1925 46.287 20.4345 45.8843 18.75H29.25V2.11576C27.5302 1.70549 25.7681 1.49882 24 1.50001C22.1925 1.50001 20.4345 1.71301 18.75 2.11576V18.75H2.11576C1.70549 20.4698 1.49882 22.2319 1.50001 24C1.50001 25.8075 1.71301 27.5655 2.11576 29.25H18.75V45.8843C20.4698 46.2945 22.2319 46.5012 24 46.5C25.8075 46.5 27.5655 46.287 29.25 45.8843V29.25H45.8843Z"
        fill="#CE1124"
      />
    </svg>
  );
};

export default Country2;

import React from "react";

const OrangeBoost = (props) => {
  return (
    <svg
      height="34"
      width="28"
      fill="none"
      viewBox="0 0 28 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.15 12.9167C27.0115 12.6418 26.7996 12.4107 26.5378 12.2489C26.276 12.0871 25.9745 12.0009 25.6667 12H17.3333V2C17.3512 1.63447 17.2483 1.27322 17.0405 0.972001C16.8326 0.670782 16.5314 0.446346 16.1833 0.333334C15.8487 0.223239 15.4878 0.222002 15.1525 0.3298C14.8171 0.437598 14.5245 0.648886 14.3167 0.933334L0.983347 19.2667C0.81629 19.5081 0.715978 19.7894 0.692564 20.0821C0.669151 20.3747 0.723466 20.6684 0.850014 20.9333C0.96655 21.2362 1.16899 21.4985 1.4325 21.688C1.696 21.8775 2.00909 21.9859 2.33335 22H10.6667V32C10.6669 32.3515 10.7783 32.6938 10.9848 32.9782C11.1914 33.2626 11.4825 33.4744 11.8167 33.5833C11.9841 33.6352 12.1581 33.6633 12.3333 33.6667C12.5963 33.6674 12.8557 33.6058 13.0904 33.487C13.325 33.3683 13.5282 33.1957 13.6833 32.9833L27.0167 14.65C27.1963 14.4013 27.3037 14.1079 27.3272 13.8021C27.3508 13.4963 27.2894 13.1899 27.15 12.9167V12.9167ZM14 26.8667V20.3333C14 19.8913 13.8244 19.4674 13.5119 19.1548C13.1993 18.8423 12.7754 18.6667 12.3333 18.6667H5.66668L14 7.13333V13.6667C14 14.1087 14.1756 14.5326 14.4882 14.8452C14.8007 15.1577 15.2247 15.3333 15.6667 15.3333H22.3333L14 26.8667Z"
        fill="url(#paint0_linear_207_538)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_207_538"
          gradientUnits="userSpaceOnUse"
          x1="0.687256"
          x2="27.3322"
          y1="16.9583"
          y2="16.9583"
        >
          <stop stopColor="#FF512F" />
          <stop offset="1" stopColor="#F09819" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default OrangeBoost;

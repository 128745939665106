import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { setUserInfo } from '../../redux/user/userSlice';

function GoogleCallback() {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true)
        let url = `/auth/callback${location.search}`
        let referBy = localStorage.getItem('referBy');
        if (referBy) {
            url = url + '&refer_by=' + referBy;
            localStorage.removeItem('referBy');
        }
        axios.get(url).then((res) => {
            setLoading(false)
            dispatch(setUserInfo(res?.data?.data))
            localStorage.setItem('userDetails', JSON.stringify(res?.data?.data))
        }).catch(e => {
            setLoading(false);
            toast.error(e?.response?.data?.message || "Something went wrong try again...")
        })
    }, []);

    return (
        <div>
            <Loader loading={loading} />
        </div>
    )
}



export default GoogleCallback;
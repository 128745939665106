import React from "react";

const Tick = (props) => {
  return (
    <svg
      height="12"
      width="17"
      fill="none"
      viewBox="0 0 17 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3283 0.411666C16.2199 0.302317 16.0908 0.215523 15.9487 0.156293C15.8065 0.0970627 15.654 0.0665684 15.5 0.0665684C15.346 0.0665684 15.1935 0.0970627 15.0513 0.156293C14.9091 0.215523 14.7801 0.302317 14.6716 0.411666L5.97998 9.115L2.32832 5.45167C2.21571 5.34289 2.08278 5.25735 1.93711 5.19995C1.79145 5.14255 1.6359 5.11439 1.47936 5.1171C1.32281 5.11981 1.16834 5.15333 1.02474 5.21573C0.881153 5.27814 0.75126 5.36822 0.642482 5.48083C0.533704 5.59344 0.448171 5.72637 0.390766 5.87204C0.333362 6.0177 0.305209 6.17325 0.307918 6.32979C0.310626 6.48633 0.344142 6.64081 0.406551 6.7844C0.46896 6.92799 0.55904 7.05789 0.671649 7.16667L5.15165 11.6467C5.26011 11.756 5.38914 11.8428 5.53131 11.902C5.67348 11.9613 5.82597 11.9918 5.97998 11.9918C6.134 11.9918 6.28649 11.9613 6.42866 11.902C6.57082 11.8428 6.69986 11.756 6.80832 11.6467L16.3283 2.12667C16.4467 2.01742 16.5412 1.88482 16.6059 1.73724C16.6705 1.58966 16.7039 1.43029 16.7039 1.26917C16.7039 1.10805 16.6705 0.948675 16.6059 0.801092C16.5412 0.653509 16.4467 0.520916 16.3283 0.411666Z"
        fill="#FBFBFB"
      />
    </svg>
  );
};

export default Tick;

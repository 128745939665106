import React from "react";

const TransactionLost = (props) => {
  return (
    <svg
      height="40"
      width="40"
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" fill="url(#paint0_linear_83_3789)" r="20" />
      <path
        d="M23.3333 11.6666H16.6667C13.9092 11.6666 11.6667 13.9091 11.6667 16.6666V27.5C11.6667 27.721 11.7545 27.9329 11.9107 28.0892C12.067 28.2455 12.279 28.3333 12.5 28.3333H23.3333C26.0908 28.3333 28.3333 26.0908 28.3333 23.3333V16.6666C28.3333 13.9091 26.0908 11.6666 23.3333 11.6666ZM23.3333 20.8333H16.6667V19.1666H23.3333V20.8333Z"
        fill="#FBFBFB"
      />
      <defs>
        <linearGradient
          id="paint0_linear_83_3789"
          gradientUnits="userSpaceOnUse"
          x1="0"
          x2="40"
          y1="20"
          y2="20"
        >
          <stop stopColor="#C84E89" />
          <stop offset="1" stopColor="#F15F79" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TransactionLost;

import React from "react";

const HamMenu = (props) => {
  return (
    <svg
      height="16"
      width="27"
      fill="none"
      viewBox="0 0 27 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.333 2.667h24a1.333 1.333 0 1 0 0-2.667h-24a1.333 1.333 0 0 0 0 2.667Zm24 10.666h-24a1.333 1.333 0 0 0 0 2.667h24a1.333 1.333 0 1 0 0-2.667Zm0-6.666h-24a1.333 1.333 0 1 0 0 2.666h24a1.333 1.333 0 1 0 0-2.666Z"
        fill="#383B52"
      />
    </svg>
  );
};

export default HamMenu;

import React from "react";

const NewDeadline = (props) => {
  return (
    <svg
      height="40"
      width="40"
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" fill="#CCCCFF" r="20" />
      <path
        d="M18 22C19.381 22 20.631 21.44 21.536 20.535C22.44 19.631 23 18.381 23 17C23 15.619 22.44 14.369 21.536 13.465C20.631 12.56 19.381 12 18 12C16.619 12 15.369 12.56 14.464 13.465C13.56 14.369 13 15.619 13 17C13 18.381 13.56 19.631 14.464 20.535C14.9278 21.0001 15.479 21.369 16.0858 21.6204C16.6926 21.8718 17.3432 22.0008 18 22ZM18 29C21.518 29 24 28 24 27C24 25 21.646 23 18 23C14.25 23 12 25 12 27C12 28 14.25 29 18 29ZM30 20H28V18C28 17.7348 27.8946 17.4804 27.7071 17.2929C27.5196 17.1054 27.2652 17 27 17C26.7348 17 26.4804 17.1054 26.2929 17.2929C26.1054 17.4804 26 17.7348 26 18V20H24C23.7348 20 23.4804 20.1054 23.2929 20.2929C23.1054 20.4804 23 20.7348 23 21C23 21.2652 23.1054 21.5196 23.2929 21.7071C23.4804 21.8946 23.7348 22 24 22H26V24C26 24.2652 26.1054 24.5196 26.2929 24.7071C26.4804 24.8946 26.7348 25 27 25C27.2652 25 27.5196 24.8946 27.7071 24.7071C27.8946 24.5196 28 24.2652 28 24V22H30C30.2652 22 30.5196 21.8946 30.7071 21.7071C30.8946 21.5196 31 21.2652 31 21C31 20.7348 30.8946 20.4804 30.7071 20.2929C30.5196 20.1054 30.2652 20 30 20Z"
        fill="url(#paint0_linear_202_2235)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_202_2235"
          gradientUnits="userSpaceOnUse"
          x1="12"
          x2="31"
          y1="20.5"
          y2="20.5"
        >
          <stop stopColor="#1488CC" />
          <stop offset="1" stopColor="#2B32B2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NewDeadline;

import React from "react";

const CircleDots = (props) => {
  return (
    <svg
      height="70"
      width="112"
      fill="none"
      viewBox="0 0 112 70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M142.715 -43.58C143.125 -42.8701 143.524 -42.1532 143.912 -41.4297C143.207 -41.8909 142.736 -42.6798 142.715 -43.58Z"
          fill="#FFA575"
        />
        <path
          d="M128.244 -61.826C129.476 -60.6702 130.67 -59.4756 131.826 -58.2441C131.379 -57.4182 130.505 -56.8571 129.5 -56.8571C128.04 -56.8571 126.857 -58.0404 126.857 -59.5C126.857 -60.505 127.418 -61.3791 128.244 -61.826Z"
          fill="#FFA575"
        />
        <path
          d="M111.43 -73.9121C112.153 -73.5239 112.87 -73.1248 113.58 -72.715C112.68 -72.736 111.891 -73.2072 111.43 -73.9121Z"
          fill="#FFA575"
        />
        <path
          d="M34.42 -72.715C35.1299 -73.1248 35.8468 -73.5239 36.5703 -73.9121C36.1091 -73.2072 35.3202 -72.736 34.42 -72.715Z"
          fill="#FFA575"
        />
        <path
          d="M16.174 -58.2441C17.3298 -59.4756 18.5244 -60.6702 19.7559 -61.826C20.5818 -61.3791 21.1429 -60.505 21.1429 -59.5C21.1429 -58.0404 19.9596 -56.8571 18.5 -56.8571C17.495 -56.8571 16.6209 -57.4182 16.174 -58.2441Z"
          fill="#FFA575"
        />
        <path
          d="M4.08788 -41.4297C4.47608 -42.1532 4.87519 -42.8701 5.28498 -43.58C5.26397 -42.6798 4.79282 -41.8909 4.08788 -41.4297Z"
          fill="#FFA575"
        />
        <path
          d="M143.912 33.4297C143.524 34.1532 143.125 34.8701 142.715 35.58C142.736 34.6798 143.207 33.8909 143.912 33.4297Z"
          fill="#FFA575"
        />
        <path
          d="M131.826 50.2441C131.379 49.4181 130.505 48.8571 129.5 48.8571C128.04 48.8571 126.857 50.0404 126.857 51.5C126.857 52.505 127.418 53.379 128.244 53.826C129.476 52.6702 130.67 51.4756 131.826 50.2441Z"
          fill="#FFA575"
        />
        <path
          d="M113.58 64.715C112.68 64.736 111.891 65.2072 111.43 65.9121C112.153 65.5239 112.87 65.1248 113.58 64.715Z"
          fill="#FFA575"
        />
        <path
          d="M36.5703 65.9121C36.1091 65.2072 35.3202 64.736 34.42 64.715C35.1299 65.1248 35.8468 65.5239 36.5703 65.9121Z"
          fill="#FFA575"
        />
        <path
          d="M19.7559 53.826C20.5818 53.379 21.1429 52.505 21.1429 51.5C21.1429 50.0404 19.9596 48.8571 18.5 48.8571C17.495 48.8571 16.6209 49.4181 16.174 50.2441C17.3298 51.4756 18.5244 52.6702 19.7559 53.826Z"
          fill="#FFA575"
        />
        <path
          d="M5.28498 35.58C5.26397 34.6798 4.79282 33.8909 4.08788 33.4297C4.47608 34.1532 4.87519 34.8701 5.28498 35.58Z"
          fill="#FFA575"
        />
        <path
          d="M52.8571 -75.3571C52.8571 -73.8975 51.6739 -72.7143 50.2143 -72.7143C48.7547 -72.7143 47.5714 -73.8975 47.5714 -75.3571C47.5714 -76.8168 48.7547 -78 50.2143 -78C51.6739 -78 52.8571 -76.8168 52.8571 -75.3571Z"
          fill="#FFA575"
        />
        <path
          d="M68.7143 -75.3571C68.7143 -73.8975 67.531 -72.7143 66.0714 -72.7143C64.6118 -72.7143 63.4286 -73.8975 63.4286 -75.3571C63.4286 -76.8168 64.6118 -78 66.0714 -78C67.531 -78 68.7143 -76.8168 68.7143 -75.3571Z"
          fill="#FFA575"
        />
        <path
          d="M84.5714 -75.3571C84.5714 -73.8975 83.3882 -72.7143 81.9286 -72.7143C80.469 -72.7143 79.2857 -73.8975 79.2857 -75.3571C79.2857 -76.8168 80.469 -78 81.9286 -78C83.3882 -78 84.5714 -76.8168 84.5714 -75.3571Z"
          fill="#FFA575"
        />
        <path
          d="M100.429 -75.3571C100.429 -73.8975 99.2453 -72.7143 97.7857 -72.7143C96.3261 -72.7143 95.1429 -73.8975 95.1429 -75.3571C95.1429 -76.8168 96.3261 -78 97.7857 -78C99.2453 -78 100.429 -76.8168 100.429 -75.3571Z"
          fill="#FFA575"
        />
        <path
          d="M37 -59.5C37 -58.0404 35.8168 -56.8571 34.3571 -56.8571C32.8975 -56.8571 31.7143 -58.0404 31.7143 -59.5C31.7143 -60.9596 32.8975 -62.1429 34.3571 -62.1429C35.8168 -62.1429 37 -60.9596 37 -59.5Z"
          fill="#FFA575"
        />
        <path
          d="M52.8571 -59.5C52.8571 -58.0404 51.6739 -56.8571 50.2143 -56.8571C48.7547 -56.8571 47.5714 -58.0404 47.5714 -59.5C47.5714 -60.9596 48.7547 -62.1429 50.2143 -62.1429C51.6739 -62.1429 52.8571 -60.9596 52.8571 -59.5Z"
          fill="#FFA575"
        />
        <path
          d="M68.7143 -59.5C68.7143 -58.0404 67.531 -56.8571 66.0714 -56.8571C64.6118 -56.8571 63.4286 -58.0404 63.4286 -59.5C63.4286 -60.9596 64.6118 -62.1429 66.0714 -62.1429C67.531 -62.1429 68.7143 -60.9596 68.7143 -59.5Z"
          fill="#FFA575"
        />
        <path
          d="M84.5714 -59.5C84.5714 -58.0404 83.3882 -56.8571 81.9286 -56.8571C80.469 -56.8571 79.2857 -58.0404 79.2857 -59.5C79.2857 -60.9596 80.469 -62.1429 81.9286 -62.1429C83.3882 -62.1429 84.5714 -60.9596 84.5714 -59.5Z"
          fill="#FFA575"
        />
        <path
          d="M100.429 -59.5C100.429 -58.0404 99.2453 -56.8571 97.7857 -56.8571C96.3261 -56.8571 95.1429 -58.0404 95.1429 -59.5C95.1429 -60.9596 96.3261 -62.1429 97.7857 -62.1429C99.2453 -62.1429 100.429 -60.9596 100.429 -59.5Z"
          fill="#FFA575"
        />
        <path
          d="M116.286 -59.5C116.286 -58.0404 115.102 -56.8571 113.643 -56.8571C112.183 -56.8571 111 -58.0404 111 -59.5C111 -60.9596 112.183 -62.1429 113.643 -62.1429C115.102 -62.1429 116.286 -60.9596 116.286 -59.5Z"
          fill="#FFA575"
        />
        <path
          d="M21.1429 -43.6429C21.1429 -42.1832 19.9596 -41 18.5 -41C17.0404 -41 15.8571 -42.1832 15.8571 -43.6429C15.8571 -45.1025 17.0404 -46.2857 18.5 -46.2857C19.9596 -46.2857 21.1429 -45.1025 21.1429 -43.6429Z"
          fill="#FFA575"
        />
        <path
          d="M37 -43.6429C37 -42.1832 35.8168 -41 34.3571 -41C32.8975 -41 31.7143 -42.1832 31.7143 -43.6429C31.7143 -45.1025 32.8975 -46.2857 34.3571 -46.2857C35.8168 -46.2857 37 -45.1025 37 -43.6429Z"
          fill="#FFA575"
        />
        <path
          d="M52.8571 -43.6429C52.8571 -42.1832 51.6739 -41 50.2143 -41C48.7547 -41 47.5714 -42.1832 47.5714 -43.6429C47.5714 -45.1025 48.7547 -46.2857 50.2143 -46.2857C51.6739 -46.2857 52.8571 -45.1025 52.8571 -43.6429Z"
          fill="#FFA575"
        />
        <path
          d="M68.7143 -43.6429C68.7143 -42.1832 67.531 -41 66.0714 -41C64.6118 -41 63.4286 -42.1832 63.4286 -43.6429C63.4286 -45.1025 64.6118 -46.2857 66.0714 -46.2857C67.531 -46.2857 68.7143 -45.1025 68.7143 -43.6429Z"
          fill="#FFA575"
        />
        <path
          d="M84.5714 -43.6429C84.5714 -42.1832 83.3882 -41 81.9286 -41C80.469 -41 79.2857 -42.1832 79.2857 -43.6429C79.2857 -45.1025 80.469 -46.2857 81.9286 -46.2857C83.3882 -46.2857 84.5714 -45.1025 84.5714 -43.6429Z"
          fill="#FFA575"
        />
        <path
          d="M100.429 -43.6429C100.429 -42.1832 99.2453 -41 97.7857 -41C96.3261 -41 95.1429 -42.1832 95.1429 -43.6429C95.1429 -45.1025 96.3261 -46.2857 97.7857 -46.2857C99.2453 -46.2857 100.429 -45.1025 100.429 -43.6429Z"
          fill="#FFA575"
        />
        <path
          d="M116.286 -43.6429C116.286 -42.1832 115.102 -41 113.643 -41C112.183 -41 111 -42.1832 111 -43.6429C111 -45.1025 112.183 -46.2857 113.643 -46.2857C115.102 -46.2857 116.286 -45.1025 116.286 -43.6429Z"
          fill="#FFA575"
        />
        <path
          d="M132.143 -43.6429C132.143 -42.1832 130.96 -41 129.5 -41C128.04 -41 126.857 -42.1832 126.857 -43.6429C126.857 -45.1025 128.04 -46.2857 129.5 -46.2857C130.96 -46.2857 132.143 -45.1025 132.143 -43.6429Z"
          fill="#FFA575"
        />
        <path
          d="M5.28571 -27.7857C5.28571 -26.3261 4.10247 -25.1429 2.64286 -25.1429C1.18325 -25.1429 0 -26.3261 0 -27.7857C0 -29.2453 1.18325 -30.4286 2.64286 -30.4286C4.10247 -30.4286 5.28571 -29.2453 5.28571 -27.7857Z"
          fill="#FFA575"
        />
        <path
          d="M21.1429 -27.7857C21.1429 -26.3261 19.9596 -25.1429 18.5 -25.1429C17.0404 -25.1429 15.8571 -26.3261 15.8571 -27.7857C15.8571 -29.2453 17.0404 -30.4286 18.5 -30.4286C19.9596 -30.4286 21.1429 -29.2453 21.1429 -27.7857Z"
          fill="#FFA575"
        />
        <path
          d="M37 -27.7857C37 -26.3261 35.8168 -25.1429 34.3571 -25.1429C32.8975 -25.1429 31.7143 -26.3261 31.7143 -27.7857C31.7143 -29.2453 32.8975 -30.4286 34.3571 -30.4286C35.8168 -30.4286 37 -29.2453 37 -27.7857Z"
          fill="#FFA575"
        />
        <path
          d="M52.8571 -27.7857C52.8571 -26.3261 51.6739 -25.1429 50.2143 -25.1429C48.7547 -25.1429 47.5714 -26.3261 47.5714 -27.7857C47.5714 -29.2453 48.7547 -30.4286 50.2143 -30.4286C51.6739 -30.4286 52.8571 -29.2453 52.8571 -27.7857Z"
          fill="#FFA575"
        />
        <path
          d="M68.7143 -27.7857C68.7143 -26.3261 67.531 -25.1429 66.0714 -25.1429C64.6118 -25.1429 63.4286 -26.3261 63.4286 -27.7857C63.4286 -29.2453 64.6118 -30.4286 66.0714 -30.4286C67.531 -30.4286 68.7143 -29.2453 68.7143 -27.7857Z"
          fill="#FFA575"
        />
        <path
          d="M84.5714 -27.7857C84.5714 -26.3261 83.3882 -25.1429 81.9286 -25.1429C80.469 -25.1429 79.2857 -26.3261 79.2857 -27.7857C79.2857 -29.2453 80.469 -30.4286 81.9286 -30.4286C83.3882 -30.4286 84.5714 -29.2453 84.5714 -27.7857Z"
          fill="#FFA575"
        />
        <path
          d="M100.429 -27.7857C100.429 -26.3261 99.2453 -25.1429 97.7857 -25.1429C96.3261 -25.1429 95.1429 -26.3261 95.1429 -27.7857C95.1429 -29.2453 96.3261 -30.4286 97.7857 -30.4286C99.2453 -30.4286 100.429 -29.2453 100.429 -27.7857Z"
          fill="#FFA575"
        />
        <path
          d="M116.286 -27.7857C116.286 -26.3261 115.102 -25.1429 113.643 -25.1429C112.183 -25.1429 111 -26.3261 111 -27.7857C111 -29.2453 112.183 -30.4286 113.643 -30.4286C115.102 -30.4286 116.286 -29.2453 116.286 -27.7857Z"
          fill="#FFA575"
        />
        <path
          d="M132.143 -27.7857C132.143 -26.3261 130.96 -25.1429 129.5 -25.1429C128.04 -25.1429 126.857 -26.3261 126.857 -27.7857C126.857 -29.2453 128.04 -30.4286 129.5 -30.4286C130.96 -30.4286 132.143 -29.2453 132.143 -27.7857Z"
          fill="#FFA575"
        />
        <path
          d="M148 -27.7857C148 -26.3261 146.817 -25.1429 145.357 -25.1429C143.898 -25.1429 142.714 -26.3261 142.714 -27.7857C142.714 -29.2453 143.898 -30.4286 145.357 -30.4286C146.817 -30.4286 148 -29.2453 148 -27.7857Z"
          fill="#FFA575"
        />
        <path
          d="M5.28571 -11.9286C5.28571 -10.469 4.10247 -9.28571 2.64286 -9.28571C1.18325 -9.28571 0 -10.469 0 -11.9286C0 -13.3882 1.18325 -14.5714 2.64286 -14.5714C4.10247 -14.5714 5.28571 -13.3882 5.28571 -11.9286Z"
          fill="#FFA575"
        />
        <path
          d="M21.1429 -11.9286C21.1429 -10.469 19.9596 -9.28571 18.5 -9.28571C17.0404 -9.28571 15.8571 -10.469 15.8571 -11.9286C15.8571 -13.3882 17.0404 -14.5714 18.5 -14.5714C19.9596 -14.5714 21.1429 -13.3882 21.1429 -11.9286Z"
          fill="#FFA575"
        />
        <path
          d="M37 -11.9286C37 -10.469 35.8168 -9.28571 34.3571 -9.28571C32.8975 -9.28571 31.7143 -10.469 31.7143 -11.9286C31.7143 -13.3882 32.8975 -14.5714 34.3571 -14.5714C35.8168 -14.5714 37 -13.3882 37 -11.9286Z"
          fill="#FFA575"
        />
        <path
          d="M52.8571 -11.9286C52.8571 -10.469 51.6739 -9.28571 50.2143 -9.28571C48.7547 -9.28571 47.5714 -10.469 47.5714 -11.9286C47.5714 -13.3882 48.7547 -14.5714 50.2143 -14.5714C51.6739 -14.5714 52.8571 -13.3882 52.8571 -11.9286Z"
          fill="#FFA575"
        />
        <path
          d="M68.7143 -11.9286C68.7143 -10.469 67.531 -9.28571 66.0714 -9.28571C64.6118 -9.28571 63.4286 -10.469 63.4286 -11.9286C63.4286 -13.3882 64.6118 -14.5714 66.0714 -14.5714C67.531 -14.5714 68.7143 -13.3882 68.7143 -11.9286Z"
          fill="#FFA575"
        />
        <path
          d="M84.5714 -11.9286C84.5714 -10.469 83.3882 -9.28571 81.9286 -9.28571C80.469 -9.28571 79.2857 -10.469 79.2857 -11.9286C79.2857 -13.3882 80.469 -14.5714 81.9286 -14.5714C83.3882 -14.5714 84.5714 -13.3882 84.5714 -11.9286Z"
          fill="#FFA575"
        />
        <path
          d="M100.429 -11.9286C100.429 -10.469 99.2453 -9.28571 97.7857 -9.28571C96.3261 -9.28571 95.1429 -10.469 95.1429 -11.9286C95.1429 -13.3882 96.3261 -14.5714 97.7857 -14.5714C99.2453 -14.5714 100.429 -13.3882 100.429 -11.9286Z"
          fill="#FFA575"
        />
        <path
          d="M116.286 -11.9286C116.286 -10.469 115.102 -9.28571 113.643 -9.28571C112.183 -9.28571 111 -10.469 111 -11.9286C111 -13.3882 112.183 -14.5714 113.643 -14.5714C115.102 -14.5714 116.286 -13.3882 116.286 -11.9286Z"
          fill="#FFA575"
        />
        <path
          d="M132.143 -11.9286C132.143 -10.469 130.96 -9.28571 129.5 -9.28571C128.04 -9.28571 126.857 -10.469 126.857 -11.9286C126.857 -13.3882 128.04 -14.5714 129.5 -14.5714C130.96 -14.5714 132.143 -13.3882 132.143 -11.9286Z"
          fill="#FFA575"
        />
        <path
          d="M148 -11.9286C148 -10.469 146.817 -9.28571 145.357 -9.28571C143.898 -9.28571 142.714 -10.469 142.714 -11.9286C142.714 -13.3882 143.898 -14.5714 145.357 -14.5714C146.817 -14.5714 148 -13.3882 148 -11.9286Z"
          fill="#FFA575"
        />
        <path
          d="M5.28571 3.92857C5.28571 5.38818 4.10247 6.57143 2.64286 6.57143C1.18325 6.57143 0 5.38818 0 3.92857C0 2.46896 1.18325 1.28571 2.64286 1.28571C4.10247 1.28571 5.28571 2.46896 5.28571 3.92857Z"
          fill="#FFA575"
        />
        <path
          d="M21.1429 3.92857C21.1429 5.38818 19.9596 6.57143 18.5 6.57143C17.0404 6.57143 15.8571 5.38818 15.8571 3.92857C15.8571 2.46896 17.0404 1.28571 18.5 1.28571C19.9596 1.28571 21.1429 2.46896 21.1429 3.92857Z"
          fill="#FFA575"
        />
        <path
          d="M37 3.92857C37 5.38818 35.8168 6.57143 34.3571 6.57143C32.8975 6.57143 31.7143 5.38818 31.7143 3.92857C31.7143 2.46896 32.8975 1.28571 34.3571 1.28571C35.8168 1.28571 37 2.46896 37 3.92857Z"
          fill="#FFA575"
        />
        <path
          d="M52.8571 3.92857C52.8571 5.38818 51.6739 6.57143 50.2143 6.57143C48.7547 6.57143 47.5714 5.38818 47.5714 3.92857C47.5714 2.46896 48.7547 1.28571 50.2143 1.28571C51.6739 1.28571 52.8571 2.46896 52.8571 3.92857Z"
          fill="#FFA575"
        />
        <path
          d="M68.7143 3.92857C68.7143 5.38818 67.531 6.57143 66.0714 6.57143C64.6118 6.57143 63.4286 5.38818 63.4286 3.92857C63.4286 2.46896 64.6118 1.28571 66.0714 1.28571C67.531 1.28571 68.7143 2.46896 68.7143 3.92857Z"
          fill="#FFA575"
        />
        <path
          d="M84.5714 3.92857C84.5714 5.38818 83.3882 6.57143 81.9286 6.57143C80.469 6.57143 79.2857 5.38818 79.2857 3.92857C79.2857 2.46896 80.469 1.28571 81.9286 1.28571C83.3882 1.28571 84.5714 2.46896 84.5714 3.92857Z"
          fill="#FFA575"
        />
        <path
          d="M100.429 3.92857C100.429 5.38818 99.2453 6.57143 97.7857 6.57143C96.3261 6.57143 95.1429 5.38818 95.1429 3.92857C95.1429 2.46896 96.3261 1.28571 97.7857 1.28571C99.2453 1.28571 100.429 2.46896 100.429 3.92857Z"
          fill="#FFA575"
        />
        <path
          d="M116.286 3.92857C116.286 5.38818 115.102 6.57143 113.643 6.57143C112.183 6.57143 111 5.38818 111 3.92857C111 2.46896 112.183 1.28571 113.643 1.28571C115.102 1.28571 116.286 2.46896 116.286 3.92857Z"
          fill="#FFA575"
        />
        <path
          d="M132.143 3.92857C132.143 5.38818 130.96 6.57143 129.5 6.57143C128.04 6.57143 126.857 5.38818 126.857 3.92857C126.857 2.46896 128.04 1.28571 129.5 1.28571C130.96 1.28571 132.143 2.46896 132.143 3.92857Z"
          fill="#FFA575"
        />
        <path
          d="M148 3.92857C148 5.38818 146.817 6.57143 145.357 6.57143C143.898 6.57143 142.714 5.38818 142.714 3.92857C142.714 2.46896 143.898 1.28571 145.357 1.28571C146.817 1.28571 148 2.46896 148 3.92857Z"
          fill="#FFA575"
        />
        <path
          d="M5.28571 19.7857C5.28571 21.2453 4.10247 22.4286 2.64286 22.4286C1.18325 22.4286 0 21.2453 0 19.7857C0 18.3261 1.18325 17.1429 2.64286 17.1429C4.10247 17.1429 5.28571 18.3261 5.28571 19.7857Z"
          fill="#FFA575"
        />
        <path
          d="M21.1429 19.7857C21.1429 21.2453 19.9596 22.4286 18.5 22.4286C17.0404 22.4286 15.8571 21.2453 15.8571 19.7857C15.8571 18.3261 17.0404 17.1429 18.5 17.1429C19.9596 17.1429 21.1429 18.3261 21.1429 19.7857Z"
          fill="#FFA575"
        />
        <path
          d="M37 19.7857C37 21.2453 35.8168 22.4286 34.3571 22.4286C32.8975 22.4286 31.7143 21.2453 31.7143 19.7857C31.7143 18.3261 32.8975 17.1429 34.3571 17.1429C35.8168 17.1429 37 18.3261 37 19.7857Z"
          fill="#FFA575"
        />
        <path
          d="M52.8571 19.7857C52.8571 21.2453 51.6739 22.4286 50.2143 22.4286C48.7547 22.4286 47.5714 21.2453 47.5714 19.7857C47.5714 18.3261 48.7547 17.1429 50.2143 17.1429C51.6739 17.1429 52.8571 18.3261 52.8571 19.7857Z"
          fill="#FFA575"
        />
        <path
          d="M68.7143 19.7857C68.7143 21.2453 67.531 22.4286 66.0714 22.4286C64.6118 22.4286 63.4286 21.2453 63.4286 19.7857C63.4286 18.3261 64.6118 17.1429 66.0714 17.1429C67.531 17.1429 68.7143 18.3261 68.7143 19.7857Z"
          fill="#FFA575"
        />
        <path
          d="M84.5714 19.7857C84.5714 21.2453 83.3882 22.4286 81.9286 22.4286C80.469 22.4286 79.2857 21.2453 79.2857 19.7857C79.2857 18.3261 80.469 17.1429 81.9286 17.1429C83.3882 17.1429 84.5714 18.3261 84.5714 19.7857Z"
          fill="#FFA575"
        />
        <path
          d="M100.429 19.7857C100.429 21.2453 99.2453 22.4286 97.7857 22.4286C96.3261 22.4286 95.1429 21.2453 95.1429 19.7857C95.1429 18.3261 96.3261 17.1429 97.7857 17.1429C99.2453 17.1429 100.429 18.3261 100.429 19.7857Z"
          fill="#FFA575"
        />
        <path
          d="M116.286 19.7857C116.286 21.2453 115.102 22.4286 113.643 22.4286C112.183 22.4286 111 21.2453 111 19.7857C111 18.3261 112.183 17.1429 113.643 17.1429C115.102 17.1429 116.286 18.3261 116.286 19.7857Z"
          fill="#FFA575"
        />
        <path
          d="M132.143 19.7857C132.143 21.2453 130.96 22.4286 129.5 22.4286C128.04 22.4286 126.857 21.2453 126.857 19.7857C126.857 18.3261 128.04 17.1429 129.5 17.1429C130.96 17.1429 132.143 18.3261 132.143 19.7857Z"
          fill="#FFA575"
        />
        <path
          d="M148 19.7857C148 21.2453 146.817 22.4286 145.357 22.4286C143.898 22.4286 142.714 21.2453 142.714 19.7857C142.714 18.3261 143.898 17.1429 145.357 17.1429C146.817 17.1429 148 18.3261 148 19.7857Z"
          fill="#FFA575"
        />
        <path
          d="M21.1429 35.6429C21.1429 37.1025 19.9596 38.2857 18.5 38.2857C17.0404 38.2857 15.8571 37.1025 15.8571 35.6429C15.8571 34.1832 17.0404 33 18.5 33C19.9596 33 21.1429 34.1832 21.1429 35.6429Z"
          fill="#FFA575"
        />
        <path
          d="M37 35.6429C37 37.1025 35.8168 38.2857 34.3571 38.2857C32.8975 38.2857 31.7143 37.1025 31.7143 35.6429C31.7143 34.1832 32.8975 33 34.3571 33C35.8168 33 37 34.1832 37 35.6429Z"
          fill="#FFA575"
        />
        <path
          d="M52.8571 35.6429C52.8571 37.1025 51.6739 38.2857 50.2143 38.2857C48.7547 38.2857 47.5714 37.1025 47.5714 35.6429C47.5714 34.1832 48.7547 33 50.2143 33C51.6739 33 52.8571 34.1832 52.8571 35.6429Z"
          fill="#FFA575"
        />
        <path
          d="M68.7143 35.6429C68.7143 37.1025 67.531 38.2857 66.0714 38.2857C64.6118 38.2857 63.4286 37.1025 63.4286 35.6429C63.4286 34.1832 64.6118 33 66.0714 33C67.531 33 68.7143 34.1832 68.7143 35.6429Z"
          fill="#FFA575"
        />
        <path
          d="M84.5714 35.6429C84.5714 37.1025 83.3882 38.2857 81.9286 38.2857C80.469 38.2857 79.2857 37.1025 79.2857 35.6429C79.2857 34.1832 80.469 33 81.9286 33C83.3882 33 84.5714 34.1832 84.5714 35.6429Z"
          fill="#FFA575"
        />
        <path
          d="M100.429 35.6429C100.429 37.1025 99.2453 38.2857 97.7857 38.2857C96.3261 38.2857 95.1429 37.1025 95.1429 35.6429C95.1429 34.1832 96.3261 33 97.7857 33C99.2453 33 100.429 34.1832 100.429 35.6429Z"
          fill="#FFA575"
        />
        <path
          d="M116.286 35.6429C116.286 37.1025 115.102 38.2857 113.643 38.2857C112.183 38.2857 111 37.1025 111 35.6429C111 34.1832 112.183 33 113.643 33C115.102 33 116.286 34.1832 116.286 35.6429Z"
          fill="#FFA575"
        />
        <path
          d="M132.143 35.6429C132.143 37.1025 130.96 38.2857 129.5 38.2857C128.04 38.2857 126.857 37.1025 126.857 35.6429C126.857 34.1832 128.04 33 129.5 33C130.96 33 132.143 34.1832 132.143 35.6429Z"
          fill="#FFA575"
        />
        <path
          d="M37 51.5C37 52.9596 35.8168 54.1429 34.3571 54.1429C32.8975 54.1429 31.7143 52.9596 31.7143 51.5C31.7143 50.0404 32.8975 48.8571 34.3571 48.8571C35.8168 48.8571 37 50.0404 37 51.5Z"
          fill="#FFA575"
        />
        <path
          d="M52.8571 51.5C52.8571 52.9596 51.6739 54.1429 50.2143 54.1429C48.7547 54.1429 47.5714 52.9596 47.5714 51.5C47.5714 50.0404 48.7547 48.8571 50.2143 48.8571C51.6739 48.8571 52.8571 50.0404 52.8571 51.5Z"
          fill="#FFA575"
        />
        <path
          d="M68.7143 51.5C68.7143 52.9596 67.531 54.1429 66.0714 54.1429C64.6118 54.1429 63.4286 52.9596 63.4286 51.5C63.4286 50.0404 64.6118 48.8571 66.0714 48.8571C67.531 48.8571 68.7143 50.0404 68.7143 51.5Z"
          fill="#FFA575"
        />
        <path
          d="M84.5714 51.5C84.5714 52.9596 83.3882 54.1429 81.9286 54.1429C80.469 54.1429 79.2857 52.9596 79.2857 51.5C79.2857 50.0404 80.469 48.8571 81.9286 48.8571C83.3882 48.8571 84.5714 50.0404 84.5714 51.5Z"
          fill="#FFA575"
        />
        <path
          d="M100.429 51.5C100.429 52.9596 99.2453 54.1429 97.7857 54.1429C96.3261 54.1429 95.1429 52.9596 95.1429 51.5C95.1429 50.0404 96.3261 48.8571 97.7857 48.8571C99.2453 48.8571 100.429 50.0404 100.429 51.5Z"
          fill="#FFA575"
        />
        <path
          d="M116.286 51.5C116.286 52.9596 115.102 54.1429 113.643 54.1429C112.183 54.1429 111 52.9596 111 51.5C111 50.0404 112.183 48.8571 113.643 48.8571C115.102 48.8571 116.286 50.0404 116.286 51.5Z"
          fill="#FFA575"
        />
        <path
          d="M52.8571 67.3571C52.8571 68.8167 51.6739 70 50.2143 70C48.7547 70 47.5714 68.8167 47.5714 67.3571C47.5714 65.8975 48.7547 64.7143 50.2143 64.7143C51.6739 64.7143 52.8571 65.8975 52.8571 67.3571Z"
          fill="#FFA575"
        />
        <path
          d="M68.7143 67.3571C68.7143 68.8167 67.531 70 66.0714 70C64.6118 70 63.4286 68.8167 63.4286 67.3571C63.4286 65.8975 64.6118 64.7143 66.0714 64.7143C67.531 64.7143 68.7143 65.8975 68.7143 67.3571Z"
          fill="#FFA575"
        />
        <path
          d="M84.5714 67.3571C84.5714 68.8167 83.3882 70 81.9286 70C80.469 70 79.2857 68.8167 79.2857 67.3571C79.2857 65.8975 80.469 64.7143 81.9286 64.7143C83.3882 64.7143 84.5714 65.8975 84.5714 67.3571Z"
          fill="#FFA575"
        />
        <path
          d="M100.429 67.3571C100.429 68.8167 99.2453 70 97.7857 70C96.3261 70 95.1429 68.8167 95.1429 67.3571C95.1429 65.8975 96.3261 64.7143 97.7857 64.7143C99.2453 64.7143 100.429 65.8975 100.429 67.3571Z"
          fill="#FFA575"
        />
      </g>
    </svg>
  );
};

export default CircleDots;

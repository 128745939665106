import React from "react";

const Twitter = (props) => {
  return (
    <svg
      height="40"
      width="40"
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0C8.955 0 0 8.955 0 20s8.955 20 20 20 20-8.955 20-20S31.045 0 20 0Zm9.612 15.076c.013.21.013.428.013.643 0 6.553-4.991 14.102-14.112 14.102-2.812 0-5.42-.817-7.616-2.223.402.045.786.063 1.197.063a9.947 9.947 0 0 0 6.156-2.116 4.96 4.96 0 0 1-4.634-3.438c.764.112 1.451.112 2.237-.09a4.956 4.956 0 0 1-3.97-4.865v-.063c.657.37 1.43.599 2.238.63a4.963 4.963 0 0 1-2.21-4.125c0-.924.24-1.768.674-2.5a14.076 14.076 0 0 0 10.223 5.183c-.7-3.37 1.817-6.098 4.844-6.098 1.428 0 2.714.598 3.62 1.562a9.793 9.793 0 0 0 3.148-1.192 4.935 4.935 0 0 1-2.179 2.728c1-.107 1.964-.384 2.857-.772a10.392 10.392 0 0 1-2.486 2.57Z"
        fill="#76A3FC"
      />
    </svg>
  );
};

export default Twitter;
